import { AssetsProvider } from "src/app/providers/assets/assets";
import { Injectable } from "@angular/core";
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { AuthenticationProvider } from "../authentication/authentication";
import { take, mergeMap } from "rxjs/operators";
import { of, EMPTY } from "rxjs";
import { CoreProvider } from "../core/core.service";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class AssetConfigResolver implements Resolve<any> {
  cardsList: any;
  assetsList: any;
  data: any;
  isSantander: string;
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authenticationService.currentUserData()) {
      return this.assetService.assetConfiguration().pipe(
        take(1),
        mergeMap((data) => {
          const list: any = data;
          if (list && list.items.length) {
            return of(data);
          } else {
            if (this.isSantander !== "true") {
              this.coreService.presentToastWithOptions(
                this.translate.instant("MSG25"),
                "primary"
              );
              this.coreService.addClassToBlankMenu("Car Configurator");
              this.router.navigate(["/mydata"]);
              if (this.router.url === "/mydata") {
                this.coreService.hideLoader();
              }
            }
            return EMPTY;
          }
        })
      );
    }
  }

  constructor(
    private authenticationService: AuthenticationProvider,
    private assetService: AssetsProvider,
    private coreService: CoreProvider,
    private translate: TranslateService,
    private router: Router
  ) {
    this.isSantander = localStorage.getItem("isSantander");
    console.log("this.router.url", this.router.url);
  }
}
