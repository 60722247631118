import { Injectable } from '@angular/core';
import { AlertController, Events, LoadingController, MenuController, ToastController, Platform } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { Storage } from '@ionic/storage';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Keychain } from '@ionic-native/keychain/ngx';
declare var window;

@Injectable({
    providedIn: 'root'
})
export class UtilService {
    isIos: boolean;
    isMobile: boolean;
    constructor(private platform: Platform, private storage: Storage,
                private fingerPrintAIO: FingerprintAIO, private keychain: Keychain) {
        if (this.platform.is('cordova')) {
            this.isMobile = true;
            if (this.platform.is('ios')) {
            this.isIos = true;
          } else {
              this.isIos = false;
          }
        } else {
            this.isMobile = false;
        }
        console.log('this.isMobile, this.isIos', this.isMobile, this.isIos);
    }

    isIosDevice() {
        return this.platform.is('ios');
    }
    isFingerprintAvailable() {
        let result = false;
        const promise = this.fingerPrintAIO.isAvailable();
        promise.then((response) => {
            alert('reponse of FP::'+ response);
            console.log('=====hhfingerprint available : ', response);
            result = true;
           });
        promise.catch((error) => {
            alert('err of FP::'+ error);
            console.log('fingerprint error : ', error);
            result  = false;
        });
        return result;
      }
      showFingerPrint() {
        return this.fingerPrintAIO.show({
          title: 'Driver Insights Biometrisches Einloggen',
          subtitle: 'Auf diese Weise können Sie sich mit Ihrem Fingerabdruck anmelden',
          description: 'Berühren Sie den Fingerabdrucksensor',
          disableBackup : true,
          cancelButtonTitle : 'ABBRECHEN'
        });
      }

    iskeyAvailable() {
        console.log('this.isIosDevice() iakey', this.isIosDevice());
        if (this.isIosDevice()) {
            console.log('Ios');
            return this.keychain.get('DIBiometricCreds');
        } else {
            console.log('not Ios');
            return this.storage.get('DIBiometricCreds');
        }
    }
    saveKey(val) {
        console.log('this.isIosDevice() save', this.isIosDevice());
        if (this.isIosDevice()) {
            console.log('Ios');
            return this.keychain.set('DIBiometricCreds', JSON.stringify(val), false);
        } else {
            console.log('not Ios');
            return this.storage.set('DIBiometricCreds', JSON.stringify(val));
        }
    }
    deleteKey() {
        console.log('this.isIosDevice() delete', this.isIosDevice());
        if (this.isIosDevice()) {
            console.log('Ios');
            return this.keychain.remove('DIBiometricCreds');
        } else {
            console.log('not Ios');
            return this.storage.remove('DIBiometricCreds');
        }
    }
    setLoggedInStatus() {
        this.storage.set('DIisFirstLogin', 'false');
    }
    getLoggedInStatus() {
        return this.storage.get('DIisFirstLogin');
    }
    deleteStorage() {
        this.storage.remove('DIisFirstLogin');
    }
    setUserOption(userData) {
        this.storage.set('DIoption', JSON.stringify(userData));
    }
    getUserOption() {
        return this.storage.get('DIoption');
    }
    deleteUserOption() {
        this.storage.remove('DIoption');
    }
}
