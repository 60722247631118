import { HttpClient, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationProvider } from '../authentication/authentication';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationProvider {
  public unreadCount: Observable<any>;
  private unreadCountSubject: BehaviorSubject<any>;

  constructor(private http: HttpClient, private authenticationService: AuthenticationProvider) {
    this.unreadCountSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('unreadCount')));
    this.unreadCount = this.unreadCountSubject.asObservable();
  }
  /**
   * api to fetch notification list
   */
  getNotification() {
    return this.http.get(`${environment.apiUrl}me/notifications`).pipe(
      map((msgs: Response) => {
        return msgs;
      }));
  }
  /**
   * 
   * @param id
   * @description API to get notification detail by id
   */
  getNotificationById(id) {
    return this.http.get(`${environment.apiUrl}me/notifications/${id}`).pipe(
      map((reasons: Response) => {
        return reasons;
      }));
  }
  /**
   *
   * @param id
   * @description API call to update notification read status
   */
  updateNotificationReadStauts(id) {
    return this.http.post<HttpResponse<any>>(`${environment.apiUrl}me/notifications/${id}/read`, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        return resp;
      }));
  }
  /**
   *
   * @param id
   * @description API call to update notification confirmed status
   */
  postNotificationConfirmStauts(id) {
    return this.http.post<HttpResponse<any>>(`${environment.apiUrl}me/notifications/${id}/confirm`, { observe: 'response' }).pipe(
      map((resp: HttpResponse<any>) => {
        return resp;
      }));
  }
  getUnreadCount() {
    return this.http.get(`${environment.apiUrl}me/notifications/unread-count`).pipe(
      map((reaspons: Response) => {
        console.log('unread count unreadCount', reaspons);
        localStorage.setItem('unreadCount', JSON.stringify(reaspons));
        this.unreadCountSubject.next(reaspons);
        return reaspons;
      }));
  }
  updateUnreadCount(val) {
    this.unreadCountSubject.next(val);
  }
}
