import { Camera } from "@ionic-native/camera/ngx";
import { Network } from "@ionic-native/network/ngx";
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { RouterModule } from "@angular/router";
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpClient,
} from "@angular/common/http";
import { JWTInterceptor } from "src/app/interceptors/jwt.interceptor";
import { ErrorInterceptor } from "src/app/interceptors/error.interceptor";
import { SavedDataService } from "./providers/saved-data/saved-data";
import { NetworkProvider } from "./providers/network/network";
import { AuthenticationProvider } from "./providers/authentication/authentication";
import { AssetsProvider } from "./providers/assets/assets";
import { Geolocation } from "@ionic-native/geolocation/ngx";

import { MyVehiclesPageModule } from "../app/pages/my-vehicles/my-vehicles.module";

import { QuestionsPopoverPageModule } from "./pages/questions-popover/questions-popover.module";
import {
  LaunchNavigator,
  LaunchNavigatorOptions,
} from "@ionic-native/launch-navigator/ngx";
import { File } from "@ionic-native/file/ngx";
import { registerLocaleData } from "@angular/common";

import localeDE from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
registerLocaleData(localeDE, "de-DE", localeDeExtra);

import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { IonicStorageModule } from "@ionic/storage";
import { FingerprintAIO } from "@ionic-native/fingerprint-aio/ngx";
import { Keychain } from "@ionic-native/keychain/ngx";
import { UpdateKmPageModule } from "./pages/update-km/update-km.module";
import { FullscreenModalPageModule } from "./pages/fullscreen-modal/fullscreen-modal.module";
import { Calendar } from "@ionic-native/calendar/ngx";
// import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
// import { Device } from '@ionic-native/device/ngx';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { environment } from "./../environments/environment";

import {
  BrowserAnimationsModule,
  NoopAnimationsModule,
} from "@angular/platform-browser/animations";
import { CookieService } from "ngx-cookie-service";
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from "ngx-cookieconsent";
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain, // it is recommended to set your domain, for cookies to work properly
  },
  position: "top",
  palette: {
    popup: {
      background: "#F1F1F1",
    },
  },
  theme: "classic",
  type: "opt-in",
  layout: "my-custom-layout",
  layouts: {
    "my-custom-layout": "{{messagelink}}{{buttons}}",
  },
  elements: {
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message">
    <span id="cmsg">{{message}}</span>
     <a aria-label="Cookie Policy" class="cc-link" target="_blank" href="https://www.holman.com/de/holman-cookie-policy"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">Cookie Policy</font></font></a>
    </span>
    <div class="cmplz-categories-wrap hide-content">
    <input type="checkbox" class="cc_chkbx" name="cc_Functional" value="cc_Functional" readonly checked>
    <label for="Functional" class="cc_chkbx_label">Funktional</label>
    <input type="checkbox" class="cc_chkbx" name="Statistics" value="cc_Statistics">
    <label for="Statistics" class="cc_chkbx_label">Statistiken</label>
    <input type="checkbox" class="cc_chkbx" name="Marketing" value="cc_Marketing">
    <label for="Marketing" class="cc_chkbx_label">Marketing</label>
    </div>
    `,
    buttons: `<div class="cc-compliance cc-highlight">
    <button aria-label="Accept cookies" class="cc-btn cc-accept-all cc-btn-no-href" (click)="delclineCookies()">Cookies akzeptieren</button>
     <button aria-label="show settings" class="cc-btn cc-save cc-show-settings cc-btn-no-href">Einstellungen anzeigen</button>
    </div>`,
  },
  content: {
    message: "We use cookies to optimize our website and our service. ",

    cookiePolicyLink: "Cookie Policy",
    cookiePolicyHref: "https://www.holman.com/de/holman-cookie-policy",
  },
};

// import { FuelCardFilter } from './providers/fuelcards/fuelcard.pipe';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent], // ,FuelCardFilter
  // entryComponents: [AssetDetailsComponent],
  // schemas: [
  //     CUSTOM_ELEMENTS_SCHEMA
  // ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    IonicModule.forRoot(),
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    MyVehiclesPageModule,
    QuestionsPopoverPageModule,
    UpdateKmPageModule,
    FullscreenModalPageModule,
    IonicStorageModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    SavedDataService,
    Network,
    NetworkProvider,
    AuthenticationProvider,
    AssetsProvider,
    Camera,
    Geolocation,
    LaunchNavigator,
    File,
    { provide: LOCALE_ID, useValue: "de-DE" },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    FingerprintAIO,
    Keychain,
    Calendar,
    CookieService,
    SocialSharing
    // Device,
    // UniqueDeviceID
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
