import { SavedDataService } from "./providers/saved-data/saved-data";
import { Component, ViewChildren, QueryList } from "@angular/core";
import {
  Platform,
  LoadingController,
  Events,
  AlertController,
  NavController,
  MenuController,
  IonRouterOutlet,
} from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { NetworkProvider } from "../app/providers/network/network";
import { AuthenticationProvider } from "./providers/authentication/authentication";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  ActivatedRoute,
} from "@angular/router";
import { CoreProvider } from "./providers/core/core.service";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { AssetsProvider } from "./providers/assets/assets";
import * as moment from "moment";
import { TranslateService, DefaultLangChangeEvent } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { UtilService } from "./providers/util/util.service";
import { NotificationProvider } from "./providers/notification/notification";
import { ThemeSwitcherService } from "./providers/theme-switcher.service";
import { NgcCookieConsentService } from "ngx-cookieconsent";
import { CookieService } from "ngx-cookie-service";
import { Location } from "@angular/common";
import { Tenant } from "./enums/tenant";
import { Title } from "@angular/platform-browser";
import { DEFAULT_INTERRUPTSOURCES, Idle  } from "@ng-idle/core";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;

  lastTimeBackPress = 0;
  timePeriodToExit = 2000;

  isLoading = false;
  isLoggedIn = false;
  isActiveUserSimulation = false;
  isMobileApp = false;
  loading: any;
  driverData: any;
  userData: any;
  meData: any;
  title: string;
  public appPages: any;
  public tabs: any;
  myData: any;
  menuDirection = "";
  items = [{ name: "click", closemenu: false }];

  menuIcon = false;
  currentUrl: string;
  finalUrl;
  string;
  language: string;
  dataURL: string;
  imprintURL: string;
  termsURL: string;
  isTouchAvailable: boolean;
  isDashboard: boolean;
  isDamageExist: boolean;
  themeName: string;
  unreadCount: any;
  isCookieExist: boolean;
  cookieValue: any;
  isIOS: boolean;
  isLoggedInUser = false;
  linkRel: string = 'noopener'; 

  constructor(
    private geolocation: Geolocation,
    private router: Router,
    public savedService: SavedDataService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    public events: Events,
    private location: Location,
    private statusBar: StatusBar,
    public loadingCtrl: LoadingController,
    public networkProvider: NetworkProvider,
    public alertCtrl: AlertController,
    public auth: AuthenticationProvider,
    public navCtrl: NavController,
    public coreService: CoreProvider,
    public themeSwitcher: ThemeSwitcherService,
    public menuCtrl: MenuController,
    private route: ActivatedRoute,
    private ccService: NgcCookieConsentService,
    private assetService: AssetsProvider,
    private translate: TranslateService,
    private utilService: UtilService,
    private notiService: NotificationProvider,
    private cookieService: CookieService,
    private titleService: Title,
    private _idle: Idle,
  ) {
    route.params.subscribe((val) => {
      this.backButtonEvent();

      this.language = localStorage.getItem("prefLang")
        ? localStorage.getItem("prefLang")
        : "de";
      this.translate.use(this.language);
      const expTime = this.auth.getExpiryTime();
      const currTime = new Date().getTime();
      if (expTime && expTime < currTime) {
        this.translate.get("SESSION_EXPIRES").subscribe(value=>{this.coreService.presentAlert(JSON.stringify(value).replace(/"/g, ''))});
        this.savedService.clearData();
        this.auth.logout();
     }
    });
    this.auth.currentUser.subscribe((x) => {
      if (x) {
        if (x.state === "Valid") {
          this.getAssetData();
          if (
            this.unreadCount == undefined ||
            this.unreadCount == null ||
            !this.unreadCount
          ) {
            this.notiService.getUnreadCount().subscribe(
              (res) => {
                this.unreadCount = res;
              },
              (err) => {
                console.log("error in notiService", err);
              }
            );
          }
        } else if (x.state === "RegistrationRequired") {
          if (
            moment().utc().valueOf() >
            moment(x.accessToken.expiresUtc).valueOf()
          ) {
            console.log("Registration expired");
            this.auth.logout();
          }
        }
      } else {
        //this.themeName = "default";
        //this.changeTheme(this.themeName);
        this.savedService.clearData();
      }
    });
    this.auth.meData.subscribe((me) => {
      if (me) {
        this.driverData = me;
        if (me.menus) {
          const index = me.menus.findIndex((m) => m.title === "Damage");
          this.isDamageExist = index > -1 ? true : false;
        }
        this.language =
          me.languageCulture.length > 2
            ? me.languageCulture.substr(0, me.languageCulture.indexOf("-"))
            : me.languageCulture;
        this.translate.setDefaultLang(this.language);
        this.translate.use(this.language);
        this.coreService.getSideMenus().then((res) => {
          if (res) {
            var dashboardTab = this.coreService.getUserTabs(me.menus, res);
            this.tabs = dashboardTab.filter((obj) => obj.id < 10);
            console.log("TAAABSSSSSS",this.tabs);
          }
        });

        const isSantander = me.customerId === "185" ? "true" : "false";
        if (this.platform.is("cordova")) {
          setTimeout(() => {
            this.checkFPAvailablity();
          }, 300);
        }
      }
    });
    this.notiService.unreadCount.subscribe((count) => {
      this.unreadCount = count;
    });
    this.auth.loggedIn.subscribe((isLogin) => {
      if (isLogin) {
        this.addGoggleAPIKey();
        this.isActiveUserSimulation = localStorage.getItem(
          "isActiveUserSimulation"
        )
          ? true
          : false;
      } else {
        this.removeGoogleAPIKey();
      }
      this.isLoggedIn = isLogin ? true : false;
    });
    // })
    /**
     * to change the direction of menu according to screen size
     */
    if (this.platform.is("cordova")) {
      this.isMobileApp = true;
      // this.menuDirection = 'end';
    } else {
      this.menuDirection = "start";
    }
    /**
     * to get users current Location
     */

    /**
     * to logout the user
     */
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        const currUrl = window.location.pathname.substring(
          window.location.pathname.lastIndexOf("/")
        );
        this.currentUrl = currUrl;
        this.finalUrl = event.url;
        this.coreService.showLoader();
        this.isDashboard = this.finalUrl === "/dashboard" ? true : false;
        this.setActiveDrawer(this.finalUrl);
        this.events.unsubscribe("slideChanged");
        if (event.url === "/logout") {
          this.logout();
        } else if (event.url.indexOf("/forgot-password-challenge/")) {
        }
        if (this.isLoggedIn && !this.isMobileApp) {
          this.menuCtrl.enable(true);
        } else {
          this.menuCtrl.enable(false);
        }
        if (event.url === "/report-damage") {
          if (!this.isDamageExist) {
            this.router.navigateByUrl("/");
          }
        }
      } else if (event instanceof NavigationEnd) {
        this.title = this.route.root.firstChild.snapshot.data
          ? this.route.root.firstChild.snapshot.data.title
          : "";
        if (this.currentUrl === this.finalUrl) {
          this.coreService.hideLoader();
        } else if (this.currentUrl === "/dashboard") {
          this.menuCtrl.close();
        }
      }
    });
    this.userData = this.auth.currentUserData();
    // tslint:disable-next-line: max-line-length
    // if (this.userData && (this.userData.state === 'Valid' || this.userData.state === 'RegistrationRequired')) {
    //   this.auth.initInterval();

    // }

    // Offline event
    this.events.subscribe("network:offline", () => {
      console.log("offline");
      document.getElementById("NetStatus").style.display = "";
    });

    // Online event
    this.events.subscribe("network:online", () => {
      console.log("online");
      document.getElementById("NetStatus").style.display = "none";
    });
    this.networkProvider.initializeNetworkEvents();
    // this.coreService.presentToastWithOptions('hello', 'Reloaded', 'primary')
    this.dataURL =
      this.language === "en"
        ? environment.dataProtectionURLEN
        : environment.dataProtectionURL;
    this.imprintURL = environment.imprintURL;
    this.termsURL = environment.termsURL;
    this.translate.onDefaultLangChange.subscribe(
      (event: DefaultLangChangeEvent) => {
        this.language = event.lang;
        this.translate.use(this.translate.getDefaultLang());
        this.updateCookieMsg();
        this.dataURL =
          this.language === "en"
            ? environment.dataProtectionURLEN
            : environment.dataProtectionURL;
      }
    );
  }
  ngOnInit() {
    this.setSantanderConfiguration();
    this.auth.idleTimer();
 
    let ccWindow = document.querySelector(".cc-window");
    if (!this.platform.is("cordova")) {
      this.isCookieExist = this.cookieService.check("cc-ari");
      this.cookieValue = "cc_Functional,cc_Statistics,cc_Marketing";
      if (this.isCookieExist) {
        ccWindow.classList.add("ion-hide");
        this.ccService.close(false);
        this.ccService.fadeOut();
      } else {
        this.ccService.open();
        this.ccService.fadeIn();
      }

      let ccSettBtn = document.querySelector(".cc-show-settings");
      let ccAccBtn = document.querySelector(".cc-accept-all");
      this.updateCookieMsg();
      // Get button element
      ccSettBtn.addEventListener("click", this.customomizeCookies.bind(this));
      ccAccBtn.addEventListener("click", this.saveCookies.bind(this));
    } else {
      ccWindow.classList.add("ion-hide");
    }
  }

  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(0, () => {
      this.routerOutlets.forEach(async (outlet: IonRouterOutlet) => {
        if (this.router.url != "/dashboard") {
          // await this.router.navigate(['/']);
          await this.location.back();
        } else if (
          this.router.url === "/dashboard" ||
          this.router.url === "/login"
        ) {
          if (
            new Date().getTime() - this.lastTimeBackPress >=
            this.timePeriodToExit
          ) {
            this.lastTimeBackPress = new Date().getTime();
            this.presentAlertConfirm();
          } else {
            navigator["app"].exitApp();
          }
        }
      });
    });
  }

  ionViewWillEnter() {
    this.language = localStorage.getItem("prefLang")
    ? localStorage.getItem("prefLang")
    : "de";
    this.translate.use(this.language);
    this.isIOS = this.platform.is("ios");
  }

  async presentAlertConfirm() {
    const alert = await this.alertCtrl.create({
      // header: 'Confirm!',
      message: this.translate.instant("EXIT_MSG"),
      buttons: [
        {
          text: this.translate.instant("FUEL_LABEL5"),
          role: "cancel",
          cssClass: "bg-cancel",
          handler: (blah) => {},
        },
        {
          text: this.translate.instant("RD_MASTER_ES_LABEL13"),
          handler: () => {
            navigator["app"].exitApp();
          },
        },
      ],
    });

    await alert.present();
  }

  updateCookieMsg() {
    let ccSettBtn = document.querySelector(".cc-show-settings");
    let ccAccBtn = document.querySelector(".cc-accept-all");
    let ccMsg = document.querySelector("#cmsg") as HTMLElement;
    var lblList = document.querySelectorAll(".cc_chkbx_label");
    this.translate
      .get([
        "COOKIE_MSG",
        "FUNC_BTN",
        "STAT_BTN",
        "MAR_BTN",
        "ACCPT_BTN",
        "SETT_BTN",
        "SS_BTN",
      ])
      .subscribe({
        next: (data) => {
          ccSettBtn.innerHTML = data["SETT_BTN"];
          ccAccBtn.innerHTML = data["ACCPT_BTN"];
          ccMsg.innerText = data["COOKIE_MSG"];
          if (lblList) {
            lblList[0].innerHTML = data["FUNC_BTN"];
            lblList[1].innerHTML = data["STAT_BTN"];
            lblList[2].innerHTML = data["MAR_BTN"];
          }
        },
        error: (err) => {},
      });
  }
  customomizeCookies(event) {
    let btnLabel =
      event.srcElement.innerText === "Einstellungen anzeigen"
        ? "Einstellungen speichern"
        : "Einstellungen anzeigen";
    event.srcElement.innerText = btnLabel;
    let btnContainer = document.querySelector(".cmplz-categories-wrap");
    btnContainer.classList.toggle("hide-content");
    var checkedList = Array.from(
      document.querySelectorAll("input[type='checkbox']:checked")
    );
    var values = checkedList.map((x) => {
      return x["value"];
    });
    this.cookieValue = values.toString();
    if (
      (btnLabel === "Einstellungen anzeigen" || btnLabel === "Show settings") &&
      btnContainer.classList.contains("hide-content")
    ) {
      this.saveCookies(event);
    } else {
      var ccList = Array.from(
        document.querySelectorAll("input[type='checkbox']")
      );
      ccList[0].innerHTML = this.translate.instant("FUNC_BTN");
      ccList[1].innerHTML = this.translate.instant("STAT_BTN");
      ccList[2].innerHTML = this.translate.instant("MAR_BTN");
    }
  }
  saveCookies(event) {
    // this.ccService.close;
    this.ccService.close(false);
    this.cookieService.set("cc-ari", this.cookieValue, { expires: 365 });
  }
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

setSantanderConfiguration(){
  this.platform.ready().then(() => {
  if (environment.tenant === Tenant.Santander) {
    localStorage.setItem("isSantander", "true");
    localStorage.setItem("santander", "true");
    this.themeName = "santendar";
    this.changeTheme(this.themeName);
    this.titleService.setTitle("Santander");
    document
      .getElementById("appFavicon")
      .setAttribute("href", "assets/icon/santander/santander.png");
  } else {
    localStorage.setItem("isSantander", "false");
    localStorage.setItem("santander", "false");
    this.themeName = "default";
    this.changeTheme(this.themeName);
    this.titleService.setTitle("Driver Insights");
  }
});
}

  logout() {
    this.savedService.clearData();
    this.auth.logout();
  }
  addGoggleAPIKey() {
    var elem = document.getElementById("googleTest");
    if (elem) {
    } else {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.id = "googleTest";
      googleMapsScript.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=places&callback=Function.prototype`
      );
      document.head.appendChild(googleMapsScript);
    }
  }
  removeGoogleAPIKey() {
    var elem = document.getElementById("googleTest");
    if (elem) {
      elem.parentNode.removeChild(elem);
    }
  }
  getLocationData() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition,
        this.showError
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }
  showPosition(position) {
    console.log(
      "Postion is ::::",
      position.coords.latitude,
      position.coords.longitude
    );
  }
  showError(error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        console.log("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        console.log("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        console.log("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        console.log("An unknown error occurred.");
        break;
    }
  }
  getuserLocation() {
    this.geolocation
      .getCurrentPosition()
      .then((resp) => {
        this.savedService.myLattitude = resp.coords.latitude;
        this.savedService.myLongitude = resp.coords.longitude;
      })
      .catch((error) => {
        console.log("Error getting location", error);
        // this.getuserLocation();
      });

    const watch = this.geolocation.watchPosition();
    watch.subscribe((data) => {
      // data can be a set of coordinates, or an error (if an error occurred).
      // data.coords.latitude
      // data.coords.longitude
    });
  }
  getAssetData() {
    if (this.savedService && this.savedService.getAssetData() != null) {
      this.getImageData();
    } else {
      if (localStorage.getItem("assets") == null) {
        this.savedService.isAssetsAPICalling = true;
        this.assetService.getAssetList("Driver").subscribe(
          (resp: any) => {
            if (resp && resp.items && resp.items.length) {
              localStorage.setItem("assets", JSON.stringify(resp));
              this.savedService.setAssetData(resp);
              this.getImageData();
            }
          },
          (err) => {
            console.log("Error while fetching asset data:: ", err);
          }
        );
      }
    }
  }
  getImageData() {
    this.savedService.assetsList.subscribe((assetData) => {
      if (assetData && assetData.items) {
        assetData.items.forEach((asset, i) => {
          if (!asset.image) {
            this.assetService.getAssetById(asset.id).subscribe((resp: any) => {
              assetData.items[i].image = resp.image;
            });
          }
        });
      }
    });
  }

  checkFPAvailablity() {
    if (this.utilService.isFingerprintAvailable) {
      this.utilService.getLoggedInStatus().then((val) => {
        if (!val) {
          this.coreService.presentAlertConfirm(
            this.translate.instant("SETTING_LABEL9")
          );
          this.events.subscribe("confirmEvent", (data) => {
            this.events.unsubscribe("confirmEvent");
            this.router.navigateByUrl("settings");
          });
          this.utilService.setLoggedInStatus();
        }
      });
    }
  }

  goToLink(url) {
    this.router.navigateByUrl("/" + url);
  }
  changeTheme(name) {
    this.themeSwitcher.setTheme(name);
    if (name === "santendar") {
      document
        .getElementsByTagName("body")[0]
        .classList.add("santander-theme-body");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("santander-theme-body");
    }
  }

  setActiveTab(tab: any) {
    this.tabs.forEach((obj) => {
      if (obj.id === tab.id) {
        obj.isActive = true;
      } else {
        obj.isActive = false;
      }
    });
  }

  setActiveDrawer(url: any) {
    if (this.tabs && this.tabs.length) {
      if (url === "/") {
        this.tabs[0].isActive = true;
      } else {
        this.tabs.forEach((obj) => {
          if ("/" + obj.url == url) {
            obj.isActive = true;
          } else {
            obj.isActive = false;
          }
        });
      }
    } else {
      return;
    }
  }
}
