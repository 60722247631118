import { Injectable } from '@angular/core';
import {Router, CanActivate , ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationProvider } from '../providers/authentication/authentication';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    /**
     *
     * @param route
     * @param state
     * description: this method is allow authorised user to access repective pages
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser: any = this.authenticationService.currentUserData();
        // tslint:disable-next-line: triple-equals
        if (currentUser && currentUser.state === 'Valid' && currentUser.accessToken.token ) {
            return true;
        } else if (currentUser && (currentUser.state === 'RegistrationRequired')) {
            this.router.navigateByUrl('/registration');
        } else {
         this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
         return false;
        }
    }
    constructor(private router: Router,
                private authenticationService: AuthenticationProvider) {

    }
}
