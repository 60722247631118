import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';


import { AuthenticationProvider } from '../authentication/authentication';
import { AppointmentsProvider } from './appointments';
import { take, mergeMap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { CoreProvider } from '../core/core.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AppointmentResolver implements Resolve<any> {
    cardsList: any;
    assetsList: any;
    data: any;
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.currentUserData()) {
            return this.appointmentService.getAppointments().pipe(
                take(1),
                mergeMap(data => {
                    const list: any = data;
                    console.log('appoinment data', data);
                    if (list && (list.items.length || list.serviceAppointments.items.length)) {
                        return of(data);
                    } else {
                        this.coreService.presentToastWithOptions(this.translate.instant('NO_APPOINTMENT'), 'primary');
                        this.coreService.addClassToBlankMenu('Meine Termine');
                        // this.router.navigate(['/dashboard']);
                        // if (this.router.url === '/dashboard') {
                        //     this.coreService.hideLoader();
                        // }
                        return EMPTY;
                    }
                })
            );
        }
    }

    constructor(private authenticationService: AuthenticationProvider,
                private appointmentService: AppointmentsProvider,
                private coreService: CoreProvider,
                private translate: TranslateService,
                private router: Router) { }
}
