import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DamagesProvider {

  constructor(private http: HttpClient) {
  }
  /**
   * @description API call to fetch damage list
   */
  getDamageList() {
    return this.http.get(`${environment.apiUrl}me/damages`).pipe(
      map((data: Response) => {
        return data;
      }));
  }
  /**
   *
   * @param id
   * @description API call get particular damage-detail based on Id 
   */
  getDamageById(id) {
    return this.http.get(`${environment.apiUrl}me/damages/${id}?%24expand=Documents`).pipe(
      map((data: Response) => {
        return data;
      }));
  }
  addComment(id, msg) {
    return this.http.post(`${environment.apiUrl}me/damages/${id}/comments`, {comment: msg }, { observe: 'response' });
  }

  reportNewDamage(reportDamageObject) {
    return this.http.post(`${environment.apiUrl}damage-survey`, reportDamageObject, { observe: 'response' });
  }
  getDamageImageData(id, docId) {
    return this.http.get(`${environment.apiUrl}me/damages/${id}?%24expand=Documents($filter=Id eq '${docId}';$expand=Data)`).pipe(
      map((data: Response) => {
        return data;
      }));
  }
  reportNewDamageForUnauth(reportDamageObject) {
      return this.http.post(`${environment.apiUrl}damage-survey-external`, reportDamageObject, { observe: 'response' });
    }
  euromasterReportNewDamage(reportDamageObject) {
    return this.http.post(`${environment.apiUrl}damage-survey-euromaster-external`, reportDamageObject, { observe: 'response' });
  }
  }
