import { Injectable } from "@angular/core";
import {
  AlertController,
  Events,
  LoadingController,
  MenuController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class CoreProvider {
  isLoading = false;
  userMenus: any;
  tabData: any;
  serviceData: any;
  tabBarMenu: any;
  toast: any;
  serviceCardImageType: string = 'jpeg';
  constructor(
    private events: Events,
    public loadingController: LoadingController,
    public menuCtrl: MenuController,
    public alertController: AlertController,
    public toastController: ToastController,
    private translate: TranslateService,
    private http: HttpClient,
    private platform: Platform
  ) {}
  /**
   *
   * @param title
   * @param msg
   * @description Common method to show alert message
   */
  async presentAlert(msg) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      message: msg,
      buttons: [
        {
          text: "OK",
          cssClass: "btn-primary",
          handler: () => {
            this.events.publish("alertEvent");
          },
        },
      ],
    });

    await alert.present();
  }
  /**
   * @description Method to show Loader
   */
  async showLoader() {
    this.isLoading = true;
    const elem = document.getElementById("appLoader");
    elem.classList.remove("loderhide");
    // return await this.loadingController.create({
    //   duration: 2000,
    // }).then(a => {
    //   a.present().then(() => {
    //     console.log('presented');
    //     if (!this.isLoading) {
    //       a.dismiss().then(() => console.log('abort presenting'));
    //     }
    //   });
    // });
  }
  /**
   * @description Method call to hide loader
   */
  async hideLoader() {
    this.isLoading = false;
    const elem = document.getElementById("appLoader");
    elem.classList.add("loderhide");
    // return await this.loadingController.dismiss().then(() => console.log('dismissed'));
  }
  /**
   *
   * @param msg
   * @param type
   * @description method to show Toast message with above mentioned properties
   */
  async presentToastWithOptions(msg, type) {
    this.presentAlert(msg);
    // try {
    //   this.toast.dismiss();
    // } catch (e) {}
    // this.toast = await this.toastController.create({
    //   // header: title,
    //   message: msg,
    //   position: 'top',
    //   color: type,
    //   cssClass: 'customToast',
    //   showCloseButton: true,
    //   closeButtonText: 'Schließen',
    //   duration: 0
    // });
    // this.toast.present();
  }
  /**
   * @description method to fetch side-menu data from local json
   */
  getSideMenus() {
    return fetch("assets/data/tab-menu.json").then((res) => res.json());
  }
  /**
   * @description method to fetch services data from local json
   */
  getServicesData() {
    return fetch("assets/data/services-data.json").then((res) => res.json());
  }
  /**
   *
   * @param apiRes
   * @param filterArr
   * @description Method to filtered api response based on filterArr
   */

  getUniqueBy = (arr, prop) => {
    const set = new Set();
    return arr.filter((o) => !set.has(o[prop]) && set.add(o[prop]));
  };

  getUserTabs(apiRes, filterArr) {
    var isMobileApp = this.platform.is("cordova");
    this.userMenus = apiRes;
    const start = {
      id: "0",
      title: "Start",
      url: "dashboard",
      icon: "assets/imgs/home-icon.svg",
      active: "assets/imgs/home-icon-selected.svg",
      desktopActive: "assets/imgs/home-icon-white.svg",
      santander: "assets/imgs/santander/home-icon-red.svg",
      name: "Start",
      germanTitle: "Start",
      isActive: true,
    };

    const contact = {
      id: "5",
      title: "Contact",
      url: "contact",
      icon: "assets/imgs/contact-icon.svg",
      active: "assets/imgs/contact-icon-selected.svg",
      desktopActive: "assets/imgs/contact-icon-white.svg",
      santander: "assets/imgs/santander/contact-icon-red.svg",
      name: "Contact",
      germanTitle: "Kontakt",
      isActive: false,
    };

    const faq = {
      id: "6",
      title: "FAQ",
      url: "faq",
      icon: "assets/imgs/faq-icon.svg",
      active: "assets/imgs/faq-icon-selected.svg",
      desktopActive: "assets/imgs/faq-icon-white.svg",
      santander: "assets/imgs/santander/faq-icon-red.svg",
      name: "FAQ",
      germanTitle: "FAQ",
      isActive: false,
    };

    var tbData = filterArr.filter((arr) => {
      return apiRes.some((f) => {
        return f.title === arr.title;
      });
    });
    const data = tbData;
    data.splice(0, 0, start);
    if (!isMobileApp) {
      data.push(contact);
      data.push(faq);
    }

    const result = this.getUniqueBy(data, "id");
    this.tabData = result.sort((a, b) => a.id - b.id);
    return this.tabData;
  }

  getUserServices(apiRes, serviceArr) {
    this.serviceData = serviceArr.filter((obj) => {
      return apiRes.some((res) => {
        return res.name === obj.name;
      });
    });
    return this.serviceData;
  }

  /**
   *
   * @param data
   * @description API call for refresh token
   */
  getRefreshToken(data) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/jwt/refresh`, data, {
        observe: "response",
      })
      .pipe(
        map((resp: any) => {
          return resp;
        })
      );
  }
  getUserSideMenus() {
    return this.tabData;
  }
  addClassToBlankMenu(label) {
    document.querySelectorAll("ion-item").forEach((item, i) => {
      if (item.textContent.includes(label)) {
        item.classList.add("active-blank");
      }
    });
  }
  async presentAlertConfirm(msg) {
    const alert = await this.alertController.create({
      header: this.translate.instant("SETTING_LABEL8"),
      message: msg,
      buttons: [
        {
          text: this.translate.instant("RD_MASTER_ES_LABEL14"),
          role: "cancel",
          cssClass: "bg-cancel",
          handler: (blah) => {
            console.log("Confirm Cancel: blah");
          },
        },
        {
          text: this.translate.instant("RD_MASTER_ES_LABEL13"),
          cssClass: "btn-primary",
          handler: () => {
            this.events.publish("confirmEvent");
          },
        },
      ],
    });

    await alert.present();
  }

  isMenuAvailable(menuName: string) {
    var isTrue: any;
    isTrue = this.tabData.filter((obj) => obj.title === menuName);
    if (isTrue.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  isServiceFuelCardAvailable(menu: string) {
    var isTrue: any;
    isTrue = this.userMenus.filter((obj) => obj.title === menu);
    if (isTrue.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
