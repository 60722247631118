import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
  } from '@angular/router';
import { AssetsProvider } from './assets';
import { EMPTY, of } from 'rxjs';
import { mergeMap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AssetResolver implements Resolve<any> {
  cardsList: any;
  configData: any;
  assetsList: any;
  data: any;
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.as.getAssetList('Driver').pipe(
      take(1),
      mergeMap(data => {
        if (data) {
          return of(data);
        } else {
          this.router.navigate(['/dashboard']);
          return EMPTY;
        }
      })
    );
  }

  constructor(private as: AssetsProvider,
              private translate: TranslateService,
              private router: Router) { }
}
