import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AssetsProvider } from 'src/app/providers/assets/assets';
import { CoreProvider } from 'src/app/providers/core/core.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-km',
  templateUrl: './update-km.page.html',
  styleUrls: ['./update-km.page.scss'],
})
export class UpdateKmPage implements OnInit {
  @Input() lastOdometerReading: string;
  @Input() readingDate: string;
  @Input() currentAssetId: any;
  readingValue: any;
  maxDate: any;
  odometerDate: any;
  isReqdErr = false;
  isValErr = false;
  customDatetimeOptions = {
    cssClass: 'datetime-bg-color'
  };

  constructor(private modalCtrl: ModalController,
              private coreService: CoreProvider,
              public translate: TranslateService,
              private assetService: AssetsProvider) { }

  ngOnInit() {
    this.odometerDate = new Date().toISOString();
    this.maxDate = new Date().toISOString();
  }
  updateKm() {
    if (!this.readingValue) {
      this.isReqdErr = true;
      this.isValErr = false;
    } else {
      this.isReqdErr = false;
      console.log('this.lastOdometerReading', typeof this.lastOdometerReading);
      console.log('this.readingValue', typeof this.readingValue, (this.readingValue < parseInt(this.lastOdometerReading)));
      if (this.readingValue < parseInt(this.lastOdometerReading)) {
        this.isValErr = true;
      } else {
        this.isValErr = false;
        this.coreService.showLoader();
        const data = {
          readingDate: new Date(this.odometerDate).toISOString(),
          readingValue: parseInt(this.readingValue)
        };
        console.log('data', this.currentAssetId, JSON.stringify(data));
        this.assetService.updateKM(this.currentAssetId, data).subscribe((resp) => {
          console.log('updateKm response:  ', resp);
          this.coreService.hideLoader();
          this.close();
          this.coreService.presentAlert(this.translate.instant('KM_SUCCESS'));
        }, (err) => {
          console.error('Error while updating km ', err);
          this.coreService.hideLoader();
        });
      }
    }

  }
  close() {
    this.modalCtrl.dismiss();
  }

}
