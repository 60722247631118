import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthenticationProvider } from "../providers/authentication/authentication";
import { CoreProvider } from "../providers/core/core.service";

@Injectable({ providedIn: "root" })
export class AccessGuard implements CanActivate {
  /**
   *
   * @param route
   * @param state
   * description: this method is to retrict loggedIn user to access unauthorised pages
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser: any = this.authenticationService.currentUserData();
    const activatedUrl = "/" + route.routeConfig.path;
    const isAccessed = false;
    console.log("route url", route.routeConfig.path);
    // tslint:disable-next-line: triple-equals
    if (
      currentUser &&
      currentUser.state === "Valid" &&
      currentUser.accessToken.token
    ) {
      const menuList = this.coreService.getUserSideMenus();
      if (menuList) {
        if (
          menuList.findIndex((menu) => "/" + menu.url === activatedUrl) !== -1
        ) {
          return true;
        } else {
          this.router.navigate(["/dashboard"]);
          return false;
        }
      }
    } else {
      this.router.navigate(["/login"], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
  constructor(
    private router: Router,
    private coreService: CoreProvider,
    private authenticationService: AuthenticationProvider
  ) {}
}
