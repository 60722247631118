import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

/*
  Generated class for the SavedDataProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class SavedDataService {
  message: string;
  isLogin = false;
  lastUrl: string;
  assetId = "";
  AppName = "ARI";
  AppVersion = "test";
  DeviceTokenId = "test";
  DeviceType = "test";
  ModelNumber = "test";
  OSVersion = "test";
  AppType = "ARIFLEET";
  token = "";
  UniqueId = "";
  UserUniqueId = "";
  userEmail = "";
  supportEmail = "";
  uuid = "";
  emailContent = "";
  myLattitude = 0;
  myLongitude = 0;
  extraObject: any;
  appointmentsDataArr = [];
  workshopDetail: any;
  queryString: string;
  private assetListSubject: BehaviorSubject<any>;
  public assetsList: Observable<any>;
  editableUserObj: any;
  isAssetsAPICalling = false;
  constructor(public http: HttpClient) {
    console.log("Hello SavedDataProvider Provider");
    this.message = "Hello SavedDataProvider Provider";
    this.assetListSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("assets"))
    );
    this.assetsList = this.assetListSubject.asObservable();
  }
  getData() {
    return this.message;
  }
  setAssetData(data) {
    this.assetListSubject = new BehaviorSubject<any>(data);
    this.assetsList = this.assetListSubject.asObservable();
    this.isAssetsAPICalling = false;
    // this.assetsList = data;
  }
  getAssetData() {
    return this.assetListSubject.value;
  }
  clearData() {
    this.assetListSubject.next(null);
    this.assetsList = undefined;
    this.myLattitude = 0;
    this.myLongitude = 0;
    this.extraObject = undefined;
    this.workshopDetail = undefined;
  }
}
// /UA-69790628-22
// view id = 189655557
// brw key = AIzaSyA-xQXi947eZdEjssn_6YFEhzYOItB0nQ8
// service key = b2fca2f10a4a22814225d91c96683d6d2718228c
// ny-certified-line-cleaners@appspot.gserviceaccount.com
