import { Tenant } from "src/app/enums/tenant";

export const environment = {
  production: true,
  apiUrl: "https://api-driverinsights.holmanfleet.de/api/v1/",
  appKey: "768cffac-969d-4b7c-be8f-dac64b414108",
  googleApiKey: "AIzaSyApWePValiBbOy20tPj5Js1nOcr22WAQls",
  // googleApiKeyForAndroid: 'AIzaSyApWePValiBbOy20tPj5Js1nOcr22WAQls',
  // googleApiKeyForIos: 'AIzaSyCUh2ipl-TE3RmWHj0TjRmaeirwcxicrUs',
  // googleApiKeyForAndroid: 'AIzaSyBQIXY0-_oNUAzjKsr9WHBNsBIsKqajahk',
  // googleApiKeyForIos: 'AIzaSyD9G8cSPjhgNadMSXb4bVnae6DDzegvvJ8',
  iOSAppUrl: "https://apps.apple.com/de/app/driverinsights/id1483734256",
  androidAppUrl:
    "https://play.google.com/store/apps/details?id=com.app.DriverInsights",
  santanderiOSAppUrl: "https://apps.apple.com/de/app/driver-insights-santander/id1668359954",
  santanderAndroidAppUrl: "https://play.google.com/store/apps/details?id=com.app.Santander",
  dataProtectionURLEN:
  "https://www.holman.com/de-en/privacy-policy-driver-insights-app/",
  dataProtectionURL:
    "https://www.holman.com/de/datenschutzerklaerung-driver-insights-app/",
  imprintURL: "https://www.holman.com/de/Impressum/",
  termsURL: "https://www.holman.com/de/nutzungsbedingungen/",
  cookieDomain: "https://driverinsights.arifleet.de/",
  tenant: Tenant.Santander,
  idleSessionExpireSeconds: 590,//590 = 10 minutes
  holmanManualVideo: "https://www.youtube.com/watch?v=RdIvDmUtQl4",
};
