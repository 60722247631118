import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { CoreProvider } from "../core/core.service";
import * as moment from "moment";
import { MenuController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { Tenant } from "src/app/enums/tenant";
import { DEFAULT_INTERRUPTSOURCES, Idle  } from "@ng-idle/core";

const CHECK_INTERVAL = 1000; // in ms
@Injectable({
  providedIn: "root",
})
export class AuthenticationProvider {
  public currentUser: Observable<any>;
  private currentUserSubject: BehaviorSubject<any>;
  public meData: Observable<any>;
  private meDataSubject: BehaviorSubject<any>;
  loggedIn: Observable<any>;
  loggedInSubject: BehaviorSubject<any>;
  timer: any;
  responseJson: any;
  isSantander: string;
  androidUrl: string;
  iOSUrl: string;
  isLoggedInUser = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    private menuCtrl: MenuController,
    private coreService: CoreProvider,
    private translate: TranslateService,
    private platform: Platform,
    private _idle: Idle,
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();
    this.meDataSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("meData"))
    );
    this.meData = this.meDataSubject.asObservable();
    console.log("this.meData============", this.meDataSubject.value);
    this.loggedInSubject = this.meDataSubject.value
      ? new BehaviorSubject<any>(true)
      : new BehaviorSubject<any>(false);
    this.loggedIn = this.loggedInSubject.asObservable();
    console.log("this.loggedInSubject============", this.loggedInSubject.value);
    this.isSantander = localStorage.getItem("isSantander");

    this.androidUrl =
      this.isSantander === "true"
        ? environment.androidAppUrl
        : environment.santanderAndroidAppUrl;
    this.iOSUrl =
      this.isSantander === "true"
        ? environment.iOSAppUrl
        : environment.santanderiOSAppUrl;
  }
  /**
   *
   * @param data
   * description: login api
   */
  login(data, page) {
    let msg: string;
    return this.http
      .post<any>(`${environment.apiUrl}auth/jwt/token`, data)
      .pipe(
        map((user) => {
          if (user && user.state) {
            var tenants = Object.values(Tenant).filter((x) => x !== "");
            if (
              (environment.tenant !== Tenant.DriverInsights &&
              (user.clientId === environment.tenant || user.clientId === null)) ||
              (environment.tenant === Tenant.DriverInsights &&
                !tenants.includes(user.clientId))
            ) {
              msg = this.checkState(user, page);
              if (user.accessToken) {
                this.responseJson = {
                  accessToken: user.accessToken.token,
                  refreshToken: user.refreshToken.token,
                };
              }
            } else {
              user.state = "";

              this.coreService.presentToastWithOptions(
                this.translate.instant("LOGIN_ANOTHER_APP_ERROR") +
                  "<a href='" +
                  this.androidUrl +
                  "'>Android</a>/<a href='" +
                  this.iOSUrl +
                  "'>iOS</a>",
                "warning"
              );
            }
          } else {
            msg = "Error";
          }
          return user;
        })
      );
  }
  /**
   *
   * @param user
   * @description Method to handle different state of user
   */
  checkState(user, page) {
    let msg: string;
    switch (user.state) {
      case "Valid": {
        msg = user.state;
        if (page === "LOGIN") {
          this.storeUserData(user);
          if (!user.dataPrivacyAckRequired) {
            this.initInterval();
          }
        }
        break;
      }
      case "PasswordChange": {
        msg = user.state;
        // this.storeUserData(user);
        // this.initInterval();
        break;
      }
      case "UnknownUser": {
        msg = user.state;
        this.router.navigateByUrl("/login");
        this.coreService.presentToastWithOptions(
          this.translate.instant("UNKNOWNUSER"),
          "warning"
        );
        break;
      }
      case "Invalid": {
        msg = user.state;
        this.coreService.presentToastWithOptions(
          this.translate.instant("MSG7"),
          "warning"
        );
        break;
      }
      case "Locked": {
        msg = user.state;
        this.coreService.presentToastWithOptions(
          this.translate.instant("MSG4"),
          "warning"
        );
        break;
      }
      case "RegistrationRequired": {
        msg = user.state;
        // this.storeUserData(user);
        this.initInterval();
        break;
      }
      case "RegistrationExpired": {
        msg = user.state;
        this.coreService.presentToastWithOptions(
          this.translate.instant("MSG6"),
          "warning"
        );
        break;
      }
      case "Disabled": {
        msg = user.state;
        this.coreService.presentToastWithOptions(
          this.translate.instant("MSG5"),
          "warning"
        );
        break;
      }
      case "DisabledByDriver": {
        msg = user.state;
        this.coreService.presentToastWithOptions(
          this.translate.instant("DEACTIVATED_USER_MESSAGE"),
          "warning"
        );
        break;
      }
    }
    return msg;
  }
  /**
   *
   * @param user
   * @description Method to store current user data in Storage
   */
  storeUserData(user) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem("currentUser", JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  /**
   * @description logout function
   */
  logout() {
    console.log("logout calls");
    clearInterval(this.timer);
    this.menuCtrl.enable(false);
    this.currentUserSubject.next(null);
    this.meDataSubject.next(null);
    localStorage.clear();
    this.setIsLoggedIn(false);
    this.platform.is("cordova")
      ? this.router.navigateByUrl("thank-you")
      : this.router.navigateByUrl("login");
  }
  /**
   * @description returns user data from  storage
   */
  currentUserData(): Object {
    return this.currentUserSubject.value;
  }
  refreshToken() {
    this.coreService.getRefreshToken(this.responseJson).subscribe(
      (res: any) => {
        const resp = res.body;
        if (resp && resp.state) {
          this.checkState(resp, "LOGIN");
          if (res.accessToken) {
            this.responseJson = {
              accessToken: resp.accessToken.token,
              refreshToken: resp.refreshToken.token,
            };
          }
        } else {
          console.log("response", res);
        }
      },
      (error) => {
        console.log("error refresh token", error);
      }
    );
  }
  /**
   * @description Method that intiate session interval call
   */
  initInterval() {
    console.log("initInterval called");
    this.setIsLoggedIn(true);
    this.timer = setInterval(() => {
      this.checkSession();
    }, CHECK_INTERVAL);
  }
  /**
   * @description Method call to return expiry time of logged-in user
   */
  getExpiryTime() {
    const userData = JSON.parse(localStorage.getItem("currentUser"));
    // console.log('remaining time :: ', moment(userData.accessToken.expiresUtc).subtract(2, 'minutes').toDate());
    return userData
      ? moment(userData.accessToken.expiresUtc).subtract(2, "minutes").valueOf()
      : 0;
  }
  getRefreshExpiry() {
    const userData = JSON.parse(localStorage.getItem("currentUser"));
    return userData
      ? moment(userData.refreshToken.expiresUtc)
          .subtract(2, "minutes")
          .valueOf()
      : 0;
  }
  /**
   * @description Method to check session expiry time of logged user token
   */
  checkSession() {
    if (this.isValidUser()) {
      const now = moment().utc().valueOf();
      const timeleft = this.getExpiryTime();
      const diff = timeleft - now;
      const isTimeout = diff < 0;
      const user: any = this.currentUserData();
      this.responseJson = {
        accessToken: user.accessToken.token,
        refreshToken: user.refreshToken.token,
      };
      if (isTimeout && this.loggedInSubject.value) {
        let refreshExpiry = this.getRefreshExpiry();
        let newDiif = refreshExpiry - now;
        let isRefreshExpired = newDiif > 0;
        if (isRefreshExpired) {
          console.log("Session times out", newDiif);
          clearInterval(this.timer);
          if (
            user.state === "RegistrationRequired" ||
            Math.abs(diff) >= 120000
          ) {
            this.logout();
          } else {
            console.log("calling refresh API");
            this.refreshToken();
          }
        }
      }
    }
  }

  idleTimer(){
    this.isLoggedInUser = this.currentUserData() ? true : false;
    console.log("CURRENT USER",this.isLoggedInUser);
    if(this.isLoggedInUser){
    const idleSessionExpireSeconds = environment.idleSessionExpireSeconds;
    this._idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this._idle.setIdle(idleSessionExpireSeconds);
    this._idle.onIdleStart.subscribe(() => {
    this.translate.get("SESSION_EXPIRES").subscribe(value=>{this.coreService.presentAlert(JSON.stringify(value).replace(/"/g, ''))});
    this.logout();
    this._idle.stop();
     });
     this._idle.watch();
    }
    else{
      this._idle.stop();
    }
  }
  /**
   * @description API call to fetch current user data
   */
  myData() {
    return this.http.get(`${environment.apiUrl}me`).pipe(
      map((data: Response) => {
        if (data) {
          localStorage.setItem("meData", JSON.stringify(data));
          this.meDataSubject.next(data);
        }
        return data;
      })
    );
  }
  /**
   * @description returns user data from  storage
   */
  meUserData(): Object {
    return this.meDataSubject.value;
  }
  /**
   *
   * @param username
   * description : API call for request temp password
   */
  requestTempPassword(username,isSantander:string) {
    return this.http
      .post<HttpResponse<Object>>(
        `${environment.apiUrl}auth/reset-registration`,
        { user: username,
          issantander: isSantander},
        { observe: "response" }
      )
      .pipe(
        map((data: HttpResponse<Object>) => {
          return data;
        })
      );
  }
  /**
   *
   * @param data
   * description API call for user-registration
   */
  signup(data) {
    return this.http.post<any>(`${environment.apiUrl}me/auth/setup`, data).pipe(
      map((resp: Response) => {
        return resp;
      })
    );
  }
  /**
   * @description : API call to get security question list
   */
  getQuestionList(langCode) {
    return this.http
      .get(`${environment.apiUrl}me/auth/security-questions`)
      .pipe(
        map((data: Response) => {
          return data;
        })
      );
  }
  /**
   *
   * @param user
   * @description : API to request reset token for user to reset password
   */
  getResetToken(user) {
    return this.http
      .post(`${environment.apiUrl}auth/reset-password/token`, user, {
        observe: "response",
      })
      .pipe(
        map((data: HttpResponse<Object>) => {
          console.log("data::", data);
          return data;
        })
      );
  }
  /**
   *
   * @param code
   * @description : Provided question list for respective input code
   */
  getSecurityQuestion(code, langCode) {
    return this.http
      .get(
        `${environment.apiUrl}auth/reset-password/security-question?Token=${code}`,
        { observe: "response" }
      )
      .pipe(
        map((data: HttpResponse<Object>) => {
          return data;
        })
      );
  }
  /**
   *
   * @param data
   * @description: API to verify user answer to respective security question
   */
  verifyAnswer(data) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/reset-password/challenge`, data)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
  /**
   *
   * @param data
   * @description API call to reset password
   */
  resetPassword(data) {
    return this.http
      .post<any>(`${environment.apiUrl}me/auth/reset-password`, data)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
  /**
   *
   * @param data
   * @description API call to reset password
   */
  forgotPassword(data) {
    return this.http
      .post<any>(`${environment.apiUrl}me/auth/forgot-password`, data)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
  /**
   * @description API call to get contact detail for damage team
   */
  damageContactAri() {
    return this.http.get(`${environment.apiUrl}damages/team-contact`).pipe(
      map((data: Response) => {
        return data;
      })
    );
  }
  /**
   * @description Method to check valid user is logged in or not
   */
  isValidUser() {
    const tempUser: any = this.currentUserData();
    if (tempUser && tempUser.accessToken.token && tempUser.state === "Valid") {
      return true;
    }
    return false;
  }
  sendDataAcknowldge() {
    return this.http
      .post<HttpResponse<Object>>(`${environment.apiUrl}me/ack-data-privacy`, {
        observe: "response",
      })
      .pipe(
        map((data: HttpResponse<Object>) => {
          return data;
        })
      );
  }
  setIsLoggedIn(isLogin) {
    this.loggedInSubject.next(isLogin);
  }
  /**
   *
   * @param token
   * description: Creates a JWT token for simulation
   */
  getJWTToken(token) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/jwt/simulation-token`, token)
      .pipe(
        map((resp) => {
          if (resp && resp.state && resp.state === "Valid") {
            if (resp.accessToken) {
              this.responseJson = {
                accessToken: resp.accessToken.token,
                refreshToken: resp.refreshToken.token,
              };
              this.storeUserData(resp);
              localStorage.setItem("isActiveUserSimulation", "true");
            }
            this.initInterval();
          }
          return resp;
        })
      );
  }
  checkUserCredentials(data) {
    return this.http
      .post<any>(`${environment.apiUrl}auth/jwt/token`, data)
      .pipe(
        map((user) => {
          return user;
        })
      );
  }
}
