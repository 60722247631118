import { Injectable, Inject } from "@angular/core";
import { DomController } from "@ionic/angular";
import { DOCUMENT } from "@angular/common";

interface Theme {
  name: string;
  styles: ThemeStyle[];
}

interface ThemeStyle {
  themeVariable: string;
  value: string;
}

@Injectable({
  providedIn: "root",
})
export class ThemeSwitcherService {
  private themes: Theme[] = [];
  private currentTheme: number = 0;

  constructor(
    private domCtrl: DomController,
    @Inject(DOCUMENT) private document
  ) {
    this.themes = [
      {
        name: "default",
        styles: [
          { themeVariable: "--font-family-M", value: "ArialRegular" },
          { themeVariable: "--font-family-B", value: "ArialBold" },
          { themeVariable: "--font-family-Bold", value: "ArialBold" },
          { themeVariable: "--font-family-NB", value: "ArialBold" },
          { themeVariable: "--primary-color", value: "#003057" },
          { themeVariable: "--top-menu-icon", value: "#003057" },
          { themeVariable: "--header-links-color", value: "#1a355e" },
          { themeVariable: "--left-nav-icon-color", value: "#003057" },
          { themeVariable: "--dashboard-box-bg-color", value: "#f2fbfe" },
          { themeVariable: "--dashboard-box-border-color", value: "#bfecf8" },
          { themeVariable: "--dashboard-box-bg-focus-color", value: "#1a355e" },
          { themeVariable: "--dashboard-box-icon-color", value: "#1b365f" },
          { themeVariable: "--dashboard-box-lbl-color", value: "#30373d" },
          { themeVariable: "--card-bg-color", value: "#003057" },
          { themeVariable: "--card-title-color", value: "#fff" },
          { themeVariable: "--card-icon-color", value: "#fff" },
          { themeVariable: "--lbl-color", value: "#252525" },
          { themeVariable: "--highlighted-lbl-color", value: "#003057" },
          { themeVariable: "--btn-color", value: "#003057" } /*Husen **/,
          { themeVariable: "--active-tab-color", value: "#2C9942" },
          { themeVariable: "--theme-main-color", value: "#003057" },
          { themeVariable: "--footer-main-color", value: "#003057" },
          { themeVariable: "--lbl-color-title", value: "#003057" },
          { themeVariable: "--active-color-menu", value: "#fff" },
          { themeVariable: "--damage-backgrd-color", value: "#003057" },
          { themeVariable: "--text-color-navbar", value: "#fff" },
          {
            themeVariable: "--btn-secondary-color",
            value: "#0069A7",
          } /*Husen **/,
          { themeVariable: "--btn-color-danger", value: "#F3E8DA" },
          { themeVariable: "--btn-hov-color", value: "#295172" },
          { themeVariable: "--dark-btn-color", value: "#1a355e" },
          { themeVariable: "--dark-btn-hov-color", value: "#fff" },
          { themeVariable: "--mainfont-color", value: "#003057" },
          {
            themeVariable: "--down-arrow",
            value: "url(./assets/imgs/down-arrow.png)",
          },
          {
            themeVariable: "--notification-icon-path",
            value: "url(./assets/icon/notification.svg)",
          },
          {
            themeVariable: "--dashboard-service-card-icon-path",
            value: "url(assets/icon/dashboard-service-card.svg)",
          },
          {
            themeVariable: "--dashboard-setting-icon-path",
            value: "url(./assets/icon/dashboard-settings.svg)",
          },
          {
            themeVariable: "--setting-icon-path",
            value: "url(assets/icon/settings.svg)",
          },
          {
            themeVariable: "--setting-icon-hov-path",
            value: "url(assets/icon/settings-light.svg)",
          },
          {
            themeVariable: "--service-card-icon-path",
            value: "url(assets/icon/service-card.svg)",
          },
          {
            themeVariable: "--service-card-icon-hov-path",
            value: "url(assets/icon/dashboard-service-card-light.svg)",
          },
          {
            themeVariable: "--dashbaord-service-card-icon-hov-path",
            value: "url(assets/icon/dashboard-service-card-light.svg)",
          },
          { themeVariable: "--side-menu-bg-color", value: "#003057" },
          { themeVariable: "--side-menu-border-bottom-color", value: "0" },
          { themeVariable: "--default-tab-color", value: "#fff" },
          { themeVariable: "--rgba-color", value: "rgba(56,128,255,0.5)" },
          { themeVariable: "--notification-bg-read", value: "#fff" },
          { themeVariable: "--notification-bg", value: "#F3E8DA" },
          { themeVariable: "--notification-read-btn-bg", value: "#e6faff" },
          {
            themeVariable: "--my-page-icon",
            value: "url(./assets/imgs/my-page.svg)",
          },
          {
            themeVariable: "--left-menu-background",
            value: "#003057",
          },
          {
            themeVariable: "--left-menu-text",
            value: "#fff",
          },
          {
            themeVariable: "--left-active-background",
            value: "#2C9942",
          },
          {
            themeVariable: "--left-menu-margin",
            value: "10px 20px 5px 10px",
          },
          {
            themeVariable: "--left-menu-radius",
            value: "10px",
          },
          { themeVariable: "--language-link-color", value: "#003057" },
          { themeVariable: "--primary-btn-background-hov-color", value: "#ffffff" },
          { themeVariable: "--primary-btn-hov-color", value: "#003057" },
          { themeVariable: "--svg-icon-fill-color", value: "#fff" },
          { themeVariable: "--active-font-weight", value: "normal" },
        ],
      },
      {
        name: "santendar",
        styles: [
          { themeVariable: "--font-family-M", value: "Santander Headline" },
          { themeVariable: "--font-family-B", value: "Santander Text" },
          { themeVariable: "--font-family-Bold", value: "Santander Headline" },
          { themeVariable: "--font-family-NB", value: "Santander Micro Text" },
          { themeVariable: "--primary-color", value: "#9E3667" },
          { themeVariable: "--header-links-color", value: "#9E3667" },
          { themeVariable: "--left-nav-icon-color", value: "#EC0000" },
          { themeVariable: "--dashboard-box-bg-color", value: "#fff" },
          { themeVariable: "--dashboard-box-border-color", value: "#6F7779" },
          { themeVariable: "--dashboard-box-bg-focus-color", value: "#9E3667" },
          { themeVariable: "--dashboard-box-icon-color", value: "#9E3667" },
          { themeVariable: "--dashboard-box-lbl-color", value: "#191919" },
          { themeVariable: "--card-title-color", value: "#191919" },
          { themeVariable: "--card-icon-color", value: "#EC0000" },
          { themeVariable: "--card-bg-color", value: "#C3DEE7" },
          { themeVariable: "--lbl-color", value: "#222222" },
          { themeVariable: "--highlighted-lbl-color", value: "#767676" },
          { themeVariable: "--btn-color", value: "#9E3667" },
          { themeVariable: "--btn-color-danger", value: "#2b0101" },
          { themeVariable: "--active-tab-color", value: "#9E3667" },
          { themeVariable: "--theme-main-color", value: "#9E3667" },
          { themeVariable: "--header-card", value: "#EFF6F9" },
          { themeVariable: "--footer-main-color", value: "#C3DEE7" },
          { themeVariable: "--lbl-color-title", value: "#222222" },
          { themeVariable: "--active-color-menu", value: "#222222" },
          { themeVariable: "--damage-backgrd-color", value: "#7a97ab" },
          { themeVariable: "--text-color-navbar", value: "#0f0f0f" },
          { themeVariable: "--top-menu-icon", value: "#EC0000" },
          
          {
            themeVariable: "--btn-secondary-color",
            value: "#732645",
          },
          { themeVariable: "--btn-hov-color", value: "#444444" },
          { themeVariable: "--dark-btn-color", value: "#9E3667" },
          { themeVariable: "--dark-btn-hov-color", value: "#fff" },
          { themeVariable: "--mainfont-color", value: "#444444" },
          {
            themeVariable: "--down-arrow",
            value: "url(./assets/imgs/santander/down-arrow.png)",
          },
          {
            themeVariable: "--notification-icon-path",
            value: "url(./assets/icon/santander/notification-red.svg)",
          },
          {
            themeVariable: "--dashboard-service-card-icon-path",
            value: "url(assets/icon/santander/dashboard-service-card.svg)",
          },
          {
            themeVariable: "--dashboard-setting-icon-path",
            value: "url(./assets/icon/santander/dashboard-settings.svg)",
          },
          {
            themeVariable: "--setting-icon-path",
            value: "url(./assets/icon/santander/settings.svg)",
          },
          {
            themeVariable: "--setting-icon-hov-path",
            value: "url(assets/icon/santander/settings.svg)",
          },
          {
            themeVariable: "--service-card-icon-path",
            value: "url(assets/icon/santander/service-card.svg)",
          },
          {
            themeVariable: "--service-card-icon-hov-path",
            value: "url(assets/icon/santander/service-card.svg)",
          },
          {
            themeVariable: "--dashbaord-service-card-icon-hov-path",
            value: "url(assets/icon/dashboard-service-card-light.svg)",
          },
          { themeVariable: "--side-menu-bg-color", value: "transparent" },
          {
            themeVariable: "--side-menu-border-bottom-color",
            value: "1px solid #0f0f0f",
          },
          { themeVariable: "--default-tab-color", value: "#fff" },
          { themeVariable: "--rgba-color", value: "rgba(243,232,218,1)" },
          { themeVariable: "--notification-bg", value: "rgba(230,31,38,0.1)" },
          {
            themeVariable: "--notification-bg-read",
            value: "rgba(230,31,38,0.050)",
          },
          { themeVariable: "--notification-read-btn-bg", value: "transparent" },
          {
            themeVariable: "--my-page-icon",
            value: "url(./assets/imgs/santander/my-page.svg)",
          },
          {
            themeVariable: "--left-menu-background",
            value: "#fff",
          },
          {
            themeVariable: "--left-menu-text",
            value: "#444444",
          },
          {
            themeVariable: "--left-active-background",
            value: "transparent",
          },
          {
            themeVariable: "--left-menu-margin",
            value: "0 0 5px",
          },
          {
            themeVariable: "--left-menu-radius",
            value: "0",
          },
          { themeVariable: "--language-link-color", value: "#000000" },
          { themeVariable: "--primary-btn-background-hov-color", value: "#444444" },
          { themeVariable: "--primary-btn-hov-color", value: "#fff" },
          { themeVariable: "--svg-icon-fill-color", value: "#9E3667" },
          { themeVariable: "--active-font-weight", value: "bold" },
        ],
      },
    ];
  }

  cycleTheme(): void {
    if (this.themes.length > this.currentTheme + 1) {
      this.currentTheme++;
    } else {
      this.currentTheme = 0;
    }

    this.setTheme(this.themes[this.currentTheme].name);
  }

  setTheme(name): void {
    let theme = this.themes.find((theme) => theme.name === name);

    this.domCtrl.write(() => {
      theme.styles.forEach((style) => {
        document.documentElement.style.setProperty(
          style.themeVariable,
          style.value
        );
      });
    });
  }
}
