import {
    HttpRequest,
    HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthenticationProvider } from '../providers/authentication/authentication';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CoreProvider } from '../providers/core/core.service';
import { SavedDataService } from '../providers/saved-data/saved-data';



@Injectable()
export class JWTInterceptor implements HttpInterceptor {
    public currentUser;
    private AUTH_HEADER_TYPE = 'Bearer ';
    token: string;
    constructor(private router: Router, private translate: TranslateService,
                private authenticationService: AuthenticationProvider,
                private coreService: CoreProvider,private savedService: SavedDataService,
    ) {
    }
    /***
     * description: this interceptor is made to add the jwt token and APP key for user authentication and API calls
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const index = req.url.indexOf('auth/jwt/');
        this.currentUser = this.authenticationService.currentUserData();
        this.token = this.currentUser ? this.AUTH_HEADER_TYPE + this.currentUser.accessToken.token : null;
        req = this.addAuthenticationToken(req);
        return next.handle(req);

    }

    private addAuthenticationToken(request: HttpRequest<any>): HttpRequest < any > {
        if (request.url.match(environment.apiUrl)) {
            if (!this.token) {
                if (request.url.match('me/auth/reset-password')) {
                    const token = localStorage.getItem('token');
                    return request.clone({
                        setHeaders: {
                            Authorization: 'Bearer ' + token
                        }
                    });
                }
                if (request.url.match('auth/security-questions') || request.url.match('reset-password/security-question')) {
                    return request.clone({
                        setHeaders: {
                            'X-APP-KEY': environment.appKey,
                            'Accept-Language': localStorage.getItem('anonymousUserLang')
                        }
                    });
                } else {
                    return request.clone({
                        setHeaders: {
                            'X-APP-KEY': environment.appKey
                        }
                    });
                }
            } else {
                if (request.url.match('auth/security-questions') || request.url.match('reset-password/security-question')) {
                    return request.clone({
                        setHeaders: {
                            Authorization: this.token,
                            'X-APP-KEY': environment.appKey,
                            'Accept-Language': localStorage.getItem('anonymousUserLang')
                        }
                    });
                } else {
                    return request.clone({
                        setHeaders: {
                            Authorization: this.token,
                            'X-APP-KEY': environment.appKey,
                        }
                    });
                }
            }
          } else {
            return request;
        }
      }


}
