import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthenticationProvider } from "../providers/authentication/authentication";
import { CoreProvider } from "../providers/core/core.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationProvider,
    private translate: TranslateService,
    private coreService: CoreProvider,
    private router: Router,
  ) {}
  /***
   * description: http intercepter to handle API errors
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        console.log("error comes", err, request);
        console.log(
          "err.status",
          err.status,
          " err.statusText",
          err.statusText
        );
        this.coreService.hideLoader();
        if (err.status === 401) {
          // auto logout if 401 response returned from api
          this.authenticationService.logout();
          window.location.href = window.location.href;
        } else if (err.status === 500) {
          // if (err.error.message.includes("User is already exists - Please try again") == true) {
          //   this.coreService.presentToastWithOptions(this.translate.instant('ALREADY_REGISTERED_USER'), 'success');
          //   this.router.navigateByUrl('/login');
          // }
            this.coreService.presentAlert(this.translate.instant("Internal_ERR"));
        } else if (err.status === 200 || err.status === 404) {
          // return throwError(err);
        } else if (err.statusText === "Unknown Error") {
          this.coreService.presentAlert(
            this.translate.instant("GENERIC_ERR_MSG")
          );
          // this.authenticationService.logout();
          // window.location.reload();
        } else if (err.status === 400) {
        } else {
          // this.coreService.presentToastWithOptions(err.error.message || err.statusText ,  'warning');
          this.coreService.presentAlert(
            this.translate.instant("GENERIC_ERR_MSG")
          );
        }
        // const error = err.error.message || err.statusText;
        return throwError(err);
      })
    );
  }
}
