import { HttpClient, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FAQProvider {

  constructor(private http: HttpClient) { }
  /**
   * api for fuel cards
   */
  getFaqQuestions(queryStr, expandStr) {
    return this.http.get(`${environment.apiUrl}faq/questions?%24filter=${queryStr}&%24expand=${expandStr}`).pipe(
      map((faq: Response) => {
        return faq;
      }));
  }


  getFaqTopics() {
    return this.http.get(`${environment.apiUrl}faq/topics`).pipe(
        map((faqTopics: Response) => {
          return faqTopics;
        }));
  }
  addQuestion(topicId, data){
    return this.http.post<HttpResponse<any>>(`${environment.apiUrl}faq/topics/${topicId}/contact-request`, data, { observe: 'response' })
    .pipe(
      map((resp: HttpResponse<any>) => {
        return resp;
      }));
  }
  getFaqContact() {
    return this.http.get(`${environment.apiUrl}faq/contact`).pipe(
        map((faqContact: Response) => {
          return faqContact;
        }));
  }
}
