import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';


import { AuthenticationProvider } from '../authentication/authentication';
import { take, mergeMap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { CoreProvider } from '../core/core.service';
import { FAQProvider } from './faq';
import { environment } from 'src/environments/environment.prod';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class FaqResolver implements Resolve<any> {
    cardsList: any;
    assetsList: any;
    data: any;
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.currentUserData()) {
            return this.faqService.getFaqTopics().pipe(
                take(1),
                mergeMap(data => {
                    const list: any = data;
                    if (list && list.length) {
                        console.log('faq data', data);
                        return of(data);
                    } else {
                        this.coreService.presentToastWithOptions(this.translate.instant('MSG21'), 'primary');
                        this.router.navigate(['/dashboard']);
                        if (this.router.url === '/dashboard') {
                            this.coreService.hideLoader();
                        }
                        return EMPTY;
                    }
                })
            );
        }
    }

    constructor(private authenticationService: AuthenticationProvider,
                private faqService: FAQProvider,
                private coreService: CoreProvider,
                private translate: TranslateService,
                private router: Router) { }
}
