import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ContractsProvider {
  constructor(private http: HttpClient) {
  }
  /**
   * @description:  API call for contract list
   */
  getContractsList() {
    return this.http.get(`${environment.apiUrl}me/contracts`).pipe(
      map((data: Response) => {
        return data;
      }));
  }
  /**
   *
   * @param id
   * @description API call to get particular contract on basis of id
   */
  getContractById(id) {
    return this.http.get(`${environment.apiUrl}me/contracts/${id}`).pipe(
      map((data: Response) => {
        return data;
      }));
  }
}
