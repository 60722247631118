import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class DriverDetailsProvider {
  constructor(private http: HttpClient) {
  }
  /**
   * @description API call to fetch Driver detail
   */
  getDriverDetails() {
    return this.http.get(`${environment.apiUrl}me`).pipe(
      map((data: Response) => {
        return data;
      }));
  }
  updateDriverDetails(formdata): Observable<any> {
    return this.http.put(`${environment.apiUrl}me`, formdata, { observe: 'response' });
  }
  /**
   *
   * @param filterName
   * @description : Api call to fetch driver-data on basis of provided filter
   */
  getDriverDetailByFilter(filterName) {
    return this.http.get(`${environment.apiUrl}me?%24expand=` + filterName).pipe(
      map((data: Response) => {
        return data;
      }));
  }

}
