import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
@Injectable({
  providedIn: "root",
})
export class AssetsProvider {
  assetsItems = [];
  constructor(private http: HttpClient) {}
  /**
   * @description API call to fetch asset-list
   */
  getAssetList(queryStr) {
    return this.http
      .get(`${environment.apiUrl}me/assets?%24expand=${queryStr}`)
      .pipe(
        map((data: Response) => {
          return data;
        })
      );
  }
  /**
   * @description API call to fetch asset-configuration data
   */
  assetConfiguration() {
    return this.http.get(`${environment.apiUrl}me/asset-configurations`).pipe(
      map((data: Response) => {
        return data;
      })
    );
  }
  getAssetById(id) {
    return this.http
      .get(`${environment.apiUrl}me/assets/${id}?%24expand=Image`)
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }
  /**
   *
   * @param assetId
   * @description API call to fetch service-card by assetId
   */
  getServiceCardByAssetId(assetId) {
    return this.http
      .get(`${environment.apiUrl}me/assets/${assetId}/maintenance-service-card`)
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }
  /**
   *
   * @param assetId
   * @param data
   * @description API to update KM
   */
  updateKM(assetId, data): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}me/assets/${assetId}/odometer-reading`,
      data,
      { observe: "response" }
    );
  }
  /**
   *
   * @param id
   * @param toAddress
   * @param data
   * @description API call to share servic-cards images with toAddress
   */
  shareServiceCard(id, toAddress, serviceCardImageBase64) {
    return this.http
      .post<HttpResponse<any>>(
        `${environment.apiUrl}me/assets/${id}/maintenance-service-card/mail?mailAddress=${toAddress}`,
        serviceCardImageBase64,
        { observe: "response" }
      )
      .pipe(
        map((resp: HttpResponse<any>) => {
          return resp;
        })
      );
  }

  getServiceCardImageByVehicleId(vehicleId, imageFormat, imageWidth) {
    return this.http
      .get(
        `${environment.apiUrl}me/assets/${vehicleId}/service-cards/images?imageFormat=${imageFormat}&width=${imageWidth}`
      )
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  getServiceCardPdfByVehicleId(vehicleId) {
    return this.http
      .get(`${environment.apiUrl}me/assets/${vehicleId}/service-cards/pdf`, { observe: "body" })
      .pipe(
        map((resp: HttpResponse<Object>) => {
          return resp;
        })
      );
  }
}
