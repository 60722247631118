import { HttpClient, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationProvider } from '../../providers/authentication/authentication';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsProvider {

  constructor(private http: HttpClient, private authenticationService: AuthenticationProvider) {
  }
  /**
   * aPi call for appointmentList
   */
  getAppointments() {
    return this.http.get(`${environment.apiUrl}me/appointments`).pipe(
      map((cards: Response) => {
        return cards;
      }));
  }
  checkUvvSubmission(id) {
    return this.http.get(`${environment.apiUrl}me/uvv-invalid-submission-details/${id}/`).pipe(map((resp: Response) => {
      return resp;
    }));
  }
  requestCompletion(data) {
    return this.http.post<HttpResponse<any>>(`${environment.apiUrl}me/uvv-performed`, data, { observe: 'response' })
    .pipe(
      map((resp: HttpResponse<any>) => {
        return resp;
      }));
  }
}
