import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UpdateKmPageRoutingModule } from './update-km-routing.module';

import { UpdateKmPage } from './update-km.page';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    UpdateKmPageRoutingModule
  ],
  declarations: [UpdateKmPage]
})
export class UpdateKmPageModule {}
