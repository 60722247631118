import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import * as download from 'downloadjs';
declare var window: any;
declare var cordova: any;
// declare var download;
@Injectable({
    providedIn: 'root'
})
export class DocumentProvider {

    constructor(private http: HttpClient) { }
    /**
     *
     * @param type
     * @description API call to fetch document list by category
     */
    getDocumentByType(type) {
        return this.http.get(`${environment.apiUrl}me/documents?%24filter=DocumentCategory%20eq%20` + type).pipe(
            map((resp: Response) => {
                return resp;
            }));
    }
    /**
     * @param id
     * @description API call to fetch detail information of documnet based on id
     */
    getDocumentById(id) {
        return this.http.get(`${environment.apiUrl}me/documents/` + id + `?%24expand=Data`).pipe(
            map((resp: Response) => {
                return resp;
            }));
    }
    /**
     * @description API call to fetch all documents of current user
     */
    getDocumentList() {
        return this.http.get(`${environment.apiUrl}me/documents`).pipe(
            map((resp: Response) => {
                return resp;
            }));
    }
    /**
     *
     * @param mimeType
     * @param base64Str
     * @param filename
     * @description Method to download base64 string file on user system
     */
    downloadDocument(mimeType, base64Str, filename) {
        const index = mimeType.indexOf('/') + 1;
        const ext = mimeType.substr(index);
        console.log('ext', ext);
        const linkSource = `data:` + mimeType + `;base64,${base64Str}`;
        download(linkSource, filename, mimeType);
        // const downloadLink = document.createElement('a');
        // const fileName = filename + '.' + ext;
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // document.body.appendChild(downloadLink);
        // setTimeout(() => {
        //     downloadLink.click();
        //     console.log('Download link', downloadLink);
        //      // Cleanup the DOM
        //     document.body.removeChild(downloadLink);
        //  }, 1000);
        // downloadLink.click();
    }
    downloadDoc(data, strFileName, strMimeType) {
        console.log('downloadDoc', strFileName, strMimeType);
        download(data, strFileName, strMimeType);

    }
    getDocumentToPreview(mimeType, base64Str, sliceSize = 512) {
        const byteCharacters = atob(base64Str);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: mimeType});
        return blob;
    }
    downloadOnDevciebase64AsPDF(filename, content, contentType, folderpath) {
        // Convert the base64 string in a Blob
        const DataBlob = this.getDocumentToPreview(contentType, content);

        console.log('Starting to write the file :3', DataBlob);
        // tslint:disable-next-line: only-arrow-functions
        window.resolveLocalFileSystemURL(folderpath, function(dir: any) {
            console.log('Access to the directory granted succesfully');
            // tslint:disable-next-line: only-arrow-functions
            dir.getFile(filename, {create: true}, function(file) {
                console.log('File created succesfully.');
                // tslint:disable-next-line: only-arrow-functions
                file.createWriter(function(fileWriter) {
                    console.log('Writing content to file');
                    fileWriter.write(DataBlob);
                    console.log('saved file in path ' + folderpath + filename + '==' + contentType);
                    // cordova.plugins.fileOpener2.open(folderpath + filename, contentType)
                    //     .then((k) => console.log('File is opened', k))
                    //     .catch((e) => console.log('Error opening file', e));
                // tslint:disable-next-line: only-arrow-functions
                    cordova.plugins.fileOpener2.open(folderpath + filename,
                    contentType,
                    {
                        error(e) {
                            console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
                        },
                        success() {
                            console.log('file opened successfully');
                        }
                    }
                );
                }, function() {
                    alert('Unable to save file in path ' + folderpath);
                });
            });
        });
}
previewDocument(mimeType, base64Str, fileName) {
    if (base64Str == '' || base64Str == undefined) {
        alert('Falied to open PDF.');
    } else {
        // For IE using atob convert base64 encoded data to byte array
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            const byteCharacters = atob(base64Str);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {
                type: mimeType
            });
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            // Directly use base 64 encoded data for rest browsers (not IE)
            const dataToOpen = this.getDocumentToPreview(mimeType, base64Str);
            const blobUrl = URL.createObjectURL(dataToOpen);
            window.open(blobUrl);
            // const base64EncodedPDF = base64Str;
            // const dataURI = 'data:application/pdf;base64,' + base64EncodedPDF;
            // window.open(dataURI, '_blank');
        }
    }

}
}
