import { Component, OnInit, Input } from '@angular/core';
import {  PopoverController, Events } from '@ionic/angular';

@Component({
  selector: 'app-questions-popover',
  templateUrl: './questions-popover.page.html',
  styleUrls: ['./questions-popover.page.scss'],
})
export class QuestionsPopoverPage implements OnInit {
  @Input() data;
  @Input() type;
  searchLand: string = '';
  filteredData: string[];

  constructor( private events: Events, private popoverController: PopoverController) {
  }
   ionViewWillEnter() {}
  ngOnInit() { 
    this.filteredData = this.data.slice();
   }
  setQuestion(ques) {
    this.events.publish('fromPopoverEvent', {data: ques});
    this.popoverController.dismiss();
  }
  setReason(reason) {
    this.events.publish('fromReasonPopoverEvent', {data: reason});
    this.popoverController.dismiss();
  }
  setTopic(topic) {
    this.events.publish('fromTopicPopoverEvent', {data: topic});
    this.popoverController.dismiss();
  }
  setAssetData(asset){
    this.events.publish('fromAssetPopoverEvent', {data: asset});
    this.popoverController.dismiss();
  }
  selectRadius(radius) {
    this.events.publish('fromRadiusEvent', {data: radius});
    this.popoverController.dismiss();
  }
  selectDriverLicenseClass(licenseClass) {
    this.events.publish('fromLicenseClassEvent', {data: licenseClass});
    this.popoverController.dismiss();
  }
  selectLand(land) {
    this.events.publish('fromLand', {data: land});
    this.popoverController.dismiss();
  }
  filterLand() {
    if (!this.searchLand || this.searchLand.trim() === '' || this.filteredData.length === 0) {
      this.filteredData = this.data;
    } 
    else{
    this.filteredData = this.data.filter(item =>
      item.toLowerCase().includes(this.searchLand.toLowerCase())
    );
  }
}
}
