import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ModalController, IonSlides } from "@ionic/angular";
import { CoreProvider } from "src/app/providers/core/core.service";

@Component({
  selector: "app-fullscreen-modal",
  templateUrl: "./fullscreen-modal.page.html",
  styleUrls: ["./fullscreen-modal.page.scss"],
})
export class FullscreenModalPage implements OnInit {
  // @Input() scFrontImg: string;
  // @Input() isSantander: string;
  // @Input() serviceCardData: any;
  @Input() serviceCardImageBase64: any;
  isFront = true;
  @ViewChild(IonSlides, { static: false }) slides: IonSlides;
  slideOpts = {
    allowTouchMove: true,
    speed: 400,
  };
  eleFront: any;
  eleBack: any;
  slideIndex: number;
  serviceCardImageType: string;
  isFirstSLide = false;
  isLastSLide = false;
  cardshow: any;

  constructor(
    private modalCtrl: ModalController,
    private coreService: CoreProvider,
    private translate: TranslateService
  ) {
    this.serviceCardImageType = coreService.serviceCardImageType;
  }
  slidePrev() {
    this.slides.slidePrev();
  }
  slideNext() {
    this.slides.slideNext();
  }

  ngOnInit() {}
  ionViewDidEnter() {
    // console.log('here on modal:: ', this.serviceCardData, this.isSantander);
    // let modal = document.querySelector('app-fullscreen-modal');
    // console.log('app-fullscreen-modal', modal.querySelector('.facce') );
    // if (this.isSantander === 'true') {
    //   this.eleFront = document.querySelector('.frontCard') as HTMLElement;
    //   this.eleBack = document.querySelector('.backCard') as HTMLElement;
    // } else {
    //   this.eleFront = document.querySelector('.face') as HTMLElement;
    //   this.eleBack = document.querySelector('.back') as HTMLElement;
    // }
    // this.eleFront = document.querySelector('.frontCard') as HTMLElement;
    // this.eleBack = document.querySelector('.backCard') as HTMLElement;
    // console.log('this.eleFront', this.eleFront);
    // console.log('this.eleBack', this.eleBack);
    // if (this.slideIndex === 0) {
    //   this.eleBack.classList.add('ion-hide');
    //   this.eleFront.classList.remove('ion-hide');
    // }
  }
  closeModal() {
    this.modalCtrl.dismiss();
  }
  onSlideLoad() {
    console.log("onSlideLoad", this.slides);
    this.slides.slideTo(0);
    this.slides.getActiveIndex().then((index) => {
      this.slideIndex = index;
      if (index === 0) {
        this.isFirstSLide =  true;
        console.log("IsFirst slide");
        // console.log('this.eleFront', this.eleFront);
        // console.log('this.eleBack', this.eleBack);
        // if (this.eleBack && this.eleFront) {
        //   this.eleBack.classList.add('ion-hide');
        //   this.eleFront.classList.remove('ion-hide');
        // }
      }
    });
  }
  goNext() {
    this.slideIndex++;
    this.isLastSLide =
    this.slideIndex >= this.cardshow.length - 1 ? true : false;
    this.isFirstSLide = this.slideIndex === 0 ? true : false
    this.slides.slideNext();
  }
  goPrev() {
    this.slideIndex--;
    this.isFirstSLide = this.slideIndex === 0 ? true : false;
    this.isLastSLide =
      this.slideIndex >= this.cardshow.length - 1 ? true : false;
    this.slides.slidePrev();
  }
  slideChanged() {
    this.slides.getActiveIndex().then((index) => {
      this.slideIndex = index;
      if (index === 0) {
        console.log("IsFirst slide");
        this.isFirstSLide =  true;
        this.isLastSLide = false;
        // this.eleBack.classList.add('ion-hide');
        // this.eleFront.classList.remove('ion-hide');
      } else {
        console.log("not First slide");
        this.isFirstSLide =  false;
        this.isLastSLide = true;
        // this.eleFront.classList.add('ion-hide');
        // this.eleBack.classList.remove('ion-hide');
      }
    });
  }
}
