import { HttpClient, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationProvider } from '../authentication/authentication';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WorkshopProvider {

  constructor(private http: HttpClient, private authenticationService: AuthenticationProvider) {}
 /**
  * @description API call to fetch list of service
  */
  getServices() {
    return this.http.get(`${environment.apiUrl}ari/services`).pipe(
      map((resp: Response) => {
        return resp;
      }));
  }
  /**
   *
   * @param queryStr
   * @description API call to fecth nearBy vendor list on basis of query string
   */
  getVendorList(queryStr, top, skip) {
    // 
    return this.http.get(`${environment.apiUrl}ari/vendors?%24filter=${queryStr}&%24count=true&%24top=${top}&%24skip=${skip}`).pipe(
      map((faq: Response) => {
        return faq;
      }));
  }
  getServiceInfo(assetId, serviceId) {
    return this.http.get(`${environment.apiUrl}me/assets/${assetId}/service-details/${serviceId}`).pipe(
      map((resp: Response) => {
        return resp;
      }));
  }
  sendMailToVendor(id, data){
    return this.http.post<HttpResponse<any>>(`${environment.apiUrl}ari/vendors/${id}/contact-request`, data, { observe: 'response' })
    .pipe(
      map((resp: HttpResponse<any>) => {
        return resp;
      }));
  }
  getLatLong(addr,key) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${addr}&key=${key}`).pipe(
      map((resp: Response) => {
        return resp;
      }));
  }
  /**
   *
   * @param id
   * @description API call to fetch service types based on id
   */
  getServiceTypesById(id) {
    return this.http.get(`${environment.apiUrl}ari/services/${id}/types`).pipe(
      map((resp: Response) => {
        return resp;
      }));
  }
}
