import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { TranslateService, DefaultLangChangeEvent } from '@ngx-translate/core';
import { NgForm, FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationProvider } from 'src/app/providers/authentication/authentication';
import { CoreProvider } from 'src/app/providers/core/core.service';
import { DamagesProvider } from 'src/app/providers/damages/damage';
import { Events, Platform, ActionSheetController, IonSlides } from '@ionic/angular';
import { Camera } from '@ionic-native/camera/ngx';
import { ModalController } from '@ionic/angular';
import { OwlDateTimeComponent } from 'ng-pick-datetime';
import { DateTimeAdapter } from 'ng-pick-datetime';


@Component({
  selector: 'report-damage',
  templateUrl: './report-damage.component.html',
  styleUrls: ['./report-damage.component.scss'],
})
export class ReportDamageComponent implements OnInit, OnDestroy {
  customDatetimeOptions = {
    cssClass: 'datetime-bg-color'
  };
  @ViewChild('dtPicker', { static: false }) dtPicker1: OwlDateTimeComponent<any>;
  isApp: boolean;
  browserName:any;
  /*---------------------------------------------------------------------*/
  constructor(public translate: TranslateService, private router: Router,
              private coreService: CoreProvider, private damageService: DamagesProvider,
              private authenticationService: AuthenticationProvider, private events: Events,
              public camera: Camera, public platform: Platform, private route: ActivatedRoute,
              public actionSheetCtrl: ActionSheetController, private formBuilder: FormBuilder,
              private cdRef: ChangeDetectorRef,
              dateTimeAdapter: DateTimeAdapter<any>,
              public modalCtrl: ModalController,
               private elementRef: ElementRef) {
                dateTimeAdapter.setLocale('de-DE');
                route.params.subscribe(val => {
                  this.isApp = this.platform.is('cordova');
                  this.isBrowser = this.platform.is('mobileweb');
                  this.isLoggedInUser = this.authenticationService.currentUserData() ? true : false;
                  if (this.isLoggedInUser) {
                    this.userData = JSON.parse(localStorage.getItem('meData'));
                  } else {
                    this.userData = null;
                  }
                  this.getDamageType();
              });
              this.events.subscribe('assetData', (data) => {
                console.log('Event called component');
                if (data) {
                  this.assetData = data;
                  if (this.damageModel && this.damageModel.vehicle) {
                    this.setVehicleFormData();
                  }
                }
              });
  }
  categories: any;
  reportTypeDamage: any;
  reportObjectDamage: any;
  drsegment: any;
  selectedTab: any;
  damageModel: any;
  /*---------------------------------------------------------------------*/
  imageBase64: any;
  selectedItem: any;
  selectedItemType: any;
  assetsList: any;
  currentAssetId: string;
  isThing = false;
  isPerson = false;
  selDate: any;
  isType = false;
  clickedImages: any[];
  selTime: any;
  isMaster = false;
  isInvalidFileType = false;
  isInvalidFileSize = false;
  invalidTypeName: string;
  invalidSizeName: string;
  employeePhone: string;
  employeeEmail: string;
  // tslint:disable-next-line: max-line-length
  emailValidator = /^((([a-z]|[A-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|[A-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|[A-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[A-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|[A-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[A-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[A-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[A-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|[A-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[A-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;
  vinValidator = /^[a-hA-Hj-nJ-NpPr-zR-Z0-9]{17}$/;
  // phoneValidator = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
  // phoneValidator = /^(\+\s?)?((?<!\+.*)\(\+?\d+([\s\-\.]?\d+)?\)|\d+)([\s\-\.]?(\(\d+([\s\-\.]?\d+)?\)|\d+))*(\s?(x|ext\.?)\s?\d+)?$/;
  phoneValidator = /^((\+?[\s\d\-]+)|((\+?[\s\d\-]+)(x|ext)+(\+?[\s\d\-]+)))$/;
  euromasterFormGroup: FormGroup;
  damageFormGroup: FormGroup;
  driverFormGroup: FormGroup;
  vehicleFormGroup: FormGroup;
  policeFormGroup: FormGroup;
  otherPartyFormGroup: FormGroup;
  ownerFormGroup: FormGroup;
  language: string;
  isLoggedInUser = false;
  selectedLabel: string;
  /**FileTypes Allowed */
  fileTypes: any;
  isActiveUserSimulation: boolean;
  userData: any;
  assetData: any;
  json: any;
  isSubmitted = false;
  isDisabled : boolean;
  datePickerObjPtBr: any = {};
  timePickerObj: any = {};
  vehicleRegistrationDate: any;
  isBrowser = false;
  // selectedDate;
  ngOnInit() {
    console.log("browserName------------>>",this.browserName);
    this.getPickerConf();
    // this.damageFormGroup = this.platform.is('cordova') ? this.createDamageForm() : this.createDamageFormWeb();
    this.damageFormGroup = this.createDamageForm();
    this.driverFormGroup = this.createDriverForm();
    this.vehicleFormGroup = this.createVehicleForm();
    this.translate.onDefaultLangChange.subscribe((event: DefaultLangChangeEvent) => {
      console.log('Event default language report damage', event);
      this.language = event.lang;
      this.translate.use(this.translate.getDefaultLang());
      // this.selectedTab = this.translate.instant(this.selectedLabel);
      // this.drsegment = this.translate.instant(this.selectedLabel);
      // this.clickedTab(this.selectedLabel);
      this.customDatetimeOptions = {
        cssClass: 'datetime-bg-color'
      };
      this.getPickerConf();
    });
      this.isDisabled = false;
  }
  checking() {
    const dt = this.selTime;
    console.log('function called', new Date(this.selTime).toISOString());
  }
  getPickerConf() {
    this.datePickerObjPtBr = {
      dateFormat: 'DD.MMM.YYYY',
      setLabel: this.translate.instant('FORGOT_LABEL9'),
      closeLabel: this.translate.instant('FUEL_LABEL8'),
      titleLabel: this.translate.instant('RD_LABEL29'),
      monthsList: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      todayLabel: this.translate.instant('TODAY_LABEL'),
    };
    this.timePickerObj = {
      timeFormat: 'HH:mm',
      inputTime: '',
      titleLabel: this.translate.instant('RD_LABEL30'),
      setLabel: this.translate.instant('FORGOT_LABEL9'),
      closeLabel: this.translate.instant('FUEL_LABEL8'),
    };
  }
  onChangeDate() {
    const dt = new Date();
    const regDt = new Date(this.damageModel.vehicle.firstRegistrationDate);
    const regDtWithTime = regDt.setHours(dt.getHours(), dt.getMinutes());
    this.damageModel.vehicle.firstRegistrationDate = new Date(new Date(regDtWithTime).toUTCString()).toISOString();

  }
  ngOnDestroy(): void {
    this.events.unsubscribe('assetData');
  }
  createDamageForm() {
    return this.formBuilder.group({
      date: ['', Validators.required],
      time: [''],
      tripType: [''],
      location: [''],
      course: ['', Validators.required],
      viewGuilty: [''],
      ddOwn: [''],
      ddOther: [''],
      ddPerson: [''],
      witness: [''],
      remarks: [''],
      acm: [''],
      culpabilityEm: [''],
      damageAreaVehicle: ['']
    });
  }
  createDriverForm() {
    return this.formBuilder.group({
      bankDetails: [''],
      bic: [''],
      driverAddress: [''],
      company: ['', Validators.required],
      driverEmail: ['', [Validators.required, Validators.pattern(this.emailValidator)]],
      driverMobile: ['', [Validators.required, Validators.pattern(this.phoneValidator)]],
      driverName: ['', Validators.required],
      phone: ['', Validators.pattern(this.phoneValidator)],
      driverLp: [''],
      employment: [''],
      iban: [''],
      supervisorEmail: ['', Validators.pattern(this.emailValidator)],
      supervisor: [''],
      taxPermit: [''],
      vatId: ['']
    });
  }
  createEuromasterForm() {
    return this.formBuilder.group({
      scn: ['', Validators.required],
      emLocation: ['', Validators.required],
      costCenter: [''],
      employeeLastName: ['', Validators.required],
      employeeFirstName: ['', Validators.required],
      employeeFunction: ['', Validators.required],
      employeeEmail: ['', [Validators.required, Validators.pattern(this.emailValidator)]],
      employeePhone: ['', [Validators.required, Validators.pattern(this.phoneValidator)]],
      salesArea: [''],
      salesManager: ['', Validators.required],
      mca: ['', Validators.required],
      majorCustomerId: ['', this.requiredIfValidator(() => this.euromasterFormGroup.get('mca').value)],
      majorCustomerName: ['', this.requiredIfValidator(() => this.euromasterFormGroup.get('mca').value)],
      otherInfo: [''],
      pc: ['', Validators.required],
      cc: ['', Validators.required],
    });
  }
  createOtherPartyForm() {
    return this.formBuilder.group({
      companyAddress: [''],
      company: [''],
      otherEmail: ['', Validators.pattern(this.emailValidator)],
      otherMobile: ['', Validators.pattern(this.phoneValidator)],
      companyName: [''],
      otherphone: ['', Validators.pattern(this.phoneValidator)],
      driverLp: ['']
    });
  }
  createVehicleForm() {
    return this.formBuilder.group({
      cardOdometerReading: [''],
      firstRegistrationDate: [''],
      manufacturerKeyNumber: ['', Validators.maxLength(5)],
      odometerReading: [''],
      vin: ['', [Validators.required, Validators.pattern(this.vinValidator)]],
      lpNumber: ['', Validators.required],
      lpTrail: [''],
      storageADamage: [''],
      isChecked: [''],
      typeCodeNumber: ['', Validators.maxLength(5)],
      vehicleDrivable: [''],
      rent: ['']
    });
  }
  createOwnerForm() {
    return this.formBuilder.group({
      address: ['', Validators.required],
      bankDetails: [''],
      bic: [''],
      contactEmail: ['', Validators.pattern(this.emailValidator)],
      contactPhone: ['', Validators.pattern(this.phoneValidator)],
      employment: [''],
      iban: [''],
      name: ['', Validators.required],
      taxPermit: [''],
      vatId: ['']
    });
  }
  createPoliceForm() {
    return this.formBuilder.group({
      paidWarned: [''],
      department: [''],
      docket: ['']
    });
  }
  requiredIfValidator(predicate) {
    return (formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    });
  }
  setTimeValidation() {
    const timeControl = this.damageFormGroup.get('time');
    if (this.isBrowser || this.isApp) {
      timeControl.setValidators([Validators.required]);
      timeControl.updateValueAndValidity();
      // this.cdRef.detectChanges();
    } else if(!this.platform.is('cordova')) {
      timeControl.clearValidators();
      timeControl.updateValueAndValidity();
    }
    if(!this.isMaster) {
      const location  = this.damageFormGroup.get('location');
      location.setValidators([Validators.required]);
      location.updateValueAndValidity();
    }
  }
  getDamageType() {
    this.coreService.showLoader();
    fetch('assets/data/report-damage.json').then(res => res.json())
      .then(json => {
        this.reportObjectDamage = json.reportObjectDamage;
        if (this.router.url === '/damage-survey-external/euromaster') {
          this.updateValidation();
          this.setFieldsForEuroMaster(json);
        } else {
          // this.damageFormGroup = this.createDamageForm();
          this.reportTypeDamage = json.damage;
          this.categories = json.categories;
          this.policeFormGroup = this.createPoliceForm();
          this.damageModel = json.damageObj;
          this.selectedLabel = 'RD_LABEL5';
          this.drsegment = this.translate.instant('RD_LABEL5');
          this.selectedTab = this.translate.instant('RD_LABEL5');
          this.fileTypes = json.allowedFileType;
        }
        if (this.router.url === '/report-damage') {
          // this.damageFormGroup = this.createDamageForm();
          this.policeFormGroup = this.createPoliceForm();
          this.isActiveUserSimulation = localStorage.getItem('isActiveUserSimulation') ? true : false;
          if (this.userData) {
            this.damageModel.driver.driverName = this.userData.firstName;
            if (this.userData.middleName) {
              this.damageModel.driver.driverName = this.damageModel.driver.driverName + ' ' + this.userData.middleName;
            }
            if (this.userData.lastName) {
              this.damageModel.driver.driverName = this.damageModel.driver.driverName + ' ' + this.userData.lastName;
            }
            this.damageModel.driver.driverPhone = this.userData.phone;
            this.damageModel.driver.driverMobile = this.userData.mobile;
            this.damageModel.driver.driverEmail = this.userData.email;
          }
          if (this.assetData) {
            this.damageModel.vehicle.vehicleIdentificationNumber = this.assetData.asset.vin;
            this.damageModel.vehicle.vehicleLicensePlateNumber = this.assetData.asset.licensePlateNumber;
          }
        }
        this.setTimeValidation();
        this.selectedTab = this.translate.instant(this.selectedLabel);
        this.drsegment = this.translate.instant(this.selectedLabel);
        this.clickedTab(this.selectedLabel);
        this.coreService.hideLoader();
      });
  }
  onSubmitErrorScroll(nextTabName,isGoToNextTab: boolean,elem?: string, formGroup?: FormGroup){
    if (formGroup.valid) {
      if (isGoToNextTab) {
        this.goToNextTab(nextTabName, elem);
      } else {
        this.selectTab(nextTabName, elem);
      }
    } else {
      this.errorScroll(formGroup);
  }
}
  updateValidation() {
    this.damageFormGroup.get('location').clearValidators();
    this.damageFormGroup.get('location').updateValueAndValidity();
  }
  checkValidity() {
    this.categories.map((obj) => {
      if (obj.form) {
        obj.isValid = this[obj.form].valid;
      } else {
        if (obj.name === 'RD_LABEL5') {
          obj.isValid = this.damageModel.kind === null ? false : true;
        } else if (obj.name === 'RD_LABEL7') {
          obj.isValid = this.damageModel.targets.length === 0 ? false : true;
        }
      }
    });
  }
  clickedTab(name) {
    this.checkValidity();
    if (name === 'Back') {
      const label = this.isMaster ? 'RD_MASTER_LABEL1' : 'RD_DRVIER_LABEL';
      const nextIndex = this.categories.map((obj) => this.translate.instant(obj.name)).indexOf(this.translate.instant('RD_OTHER_LABEL'));
      this.drsegment = nextIndex < 0 ? this.translate.instant(label) : this.translate.instant('RD_OTHER_LABEL');
      this.selectedLabel = nextIndex < 0 ? label : 'RD_OTHER_LABEL';
    } else if (name === 'Other') {
      const label = this.isMaster ? 'RD_MASTER_LABEL1' : 'RD_DRVIER_LABEL';
      this.drsegment = this.translate.instant(label);
      this.selectedLabel = label;
    } else {
      this.drsegment = this.translate.instant(name);
      this.selectedLabel = name;
      if (this.assetData && this.damageModel && this.damageModel.vehicle && !this.damageModel.vehicle.vehicleIdentificationNumber) {
        this.setVehicleFormData();
      }
    }
  }
  setVehicleFormData() {
    const tabName = this.drsegment;
    this.damageModel.vehicle.vehicleIdentificationNumber = this.assetData.asset.vin;
    this.damageModel.vehicle.vehicleLicensePlateNumber = this.assetData.asset.licensePlateNumber;
    this.clickedTab('RD_LABEL5');
    setTimeout(() => {
      this.cdRef.detectChanges();
      this.clickedTab(tabName);
      this.makeFormControlsDirty('vehicleFormGroup');
    }, 20);
  }
  clickedItemtype(event, selected) {
    this.selectedItem = selected;
    this.damageModel.kind = selected.value;
    this.isType = true;
    const index = this.categories.map((obj) => this.translate.instant(obj.name)).indexOf(this.translate.instant('RD_OTHER_LABEL'));
    if (selected.value === 'AccidentWithOtherVehicle') {
        this.otherPartyFormGroup = this.createOtherPartyForm();
        if (index < 0) {
          if (this.isMaster) {
            this.categories.splice(6, 0, { name: 'RD_OTHER_LABEL', width: '6', form: 'otherPartyFormGroup', isValid: false });
          } else {
            this.categories.splice(4, 0, { name: 'RD_OTHER_LABEL', width: '6', form: 'otherPartyFormGroup', isValid: false });
          }
        }
      } else {
        if (index >= 0) {
          this.categories.splice(index, 1);
        }
      }
    this.checkValidity();
  }
  goToNextTab(nextTabName,elem?: string) {
    
    const currentTabValue = (this.isBrowser || this.isApp) ? this.selectedTab : this.drsegment;
    const index = this.categories.map((obj) => this.translate.instant(obj.name)).indexOf(currentTabValue);
    const form = this.categories[index].form;
    const i = index + 1;
    nextTabName = this.categories[i].name;
    if (this.isMaster) {
      if ((form && this[form].valid) || (index === 1 || index === 2)) {
        this.categories[index].isValid = true;
        if (nextTabName) {
          if (form === 'ownerFormGroup') {
            // tslint:disable-next-line: max-line-length
            const nextIndex = this.categories.map((obj) => this.translate.instant(obj.name)).indexOf(this.translate.instant('RD_OTHER_LABEL'));
            // tslint:disable-next-line: max-line-length
            nextIndex < 0 ? this.selectTab(this.translate.instant('RD_VEHICLE_LABEL')) : this.selectTab(this.translate.instant('RD_OTHER_LABEL'));
          } else {
            this.selectTab(nextTabName,elem);
          }
        } else {
          if (this.isApp || this.isBrowser) {
            this.selectTab(nextTabName,elem);
          }
          this.setSegment(index);
        }
      } else {
        this.makeFormControlsDirty(form);
        return;
      }
    } else {
      if ((form && this[form].valid) || (index === 1 || index === 0)) {
        this.categories[index].isValid = true;
        if (nextTabName) {
          if (form === 'driverFormGroup') {
            // tslint:disable-next-line: max-line-length
            const nextIndex = this.categories.map((obj) => this.translate.instant(obj.name)).indexOf(this.translate.instant('RD_OTHER_LABEL'));
            // tslint:disable-next-line: max-line-length
            nextIndex < 0 ? this.selectTab(this.translate.instant('RD_VEHICLE_LABEL')) : this.selectTab(this.translate.instant('RD_OTHER_LABEL'));
          } else {
            this.selectTab(nextTabName,elem);
          }
        } else {
          if (this.isApp || this.isBrowser) {

            this.selectTab(nextTabName,elem);
          }
          this.setSegment(index);
        }
      } else {
        this.makeFormControlsDirty(form);
        return;
      }
    }
  }
  setSegment(index) {
    const i = ++index;
    this.selectedLabel = this.categories[i].name;
    this.drsegment = this.translate.instant(this.categories[i].name);
  }
  makeFormControlsDirty(form) {
    const controls = this[form].controls;
    Object.keys(this[form].controls).forEach(key => {
      this[form].get(key).markAsDirty();
    });
  }
  clickedItemObject(event, selected: any) {
    const flag = selected.isSelected;
    selected.isSelected = !flag;
    if (!this.selectedItemType || !this.selectedItemType.length) {
      this.selectedItemType = [];
      if (selected.isSelected) {
        this.selectedItemType.push(selected.value);
      }
    } else {
      if (selected.isSelected) {
        this.selectedItemType.push(selected.value);
      } else {
        const index = this.selectedItemType.indexOf(selected.value);
        this.selectedItemType.splice(index, 1);
      }
    }
    this.damageModel.targets = this.selectedItemType;
    this.isThing = this.selectedItemType.indexOf('Thing') > -1 ? true : false;
    this.isPerson = this.selectedItemType.indexOf('Person') > -1 ? true : false;
    this.checkValidity();
  }
  errorScroll(formGroup: FormGroup){
      for (let field in formGroup.controls) {
          const control = formGroup.get(field);
          if (control.invalid) {           
            const invalidField = document.getElementById(field);
            const validators = control.validator({} as AbstractControl); // Calling the validator function
            const isRequired = validators && validators.required;     
            if (isRequired && control.value === null) {
              control.markAsDirty();
            }
            if (invalidField) {          
               invalidField.scrollIntoView({ behavior: 'smooth', block: 'center'});
              break;
            }
          }
      }
      
}
  scroll(elem: string){
    document.querySelector(`#${elem}`).scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
   selectTab(tabValue, elem?: string) {
    if (tabValue === 'Back') {
      const nextIndex = this.categories.map((obj) => this.translate.instant(obj.name)).indexOf(this.translate.instant('RD_OTHER_LABEL'));
      this.selectedTab = nextIndex < 0 ? this.translate.instant('RD_DRVIER_LABEL') : this.translate.instant('RD_OTHER_LABEL');
      this.drsegment = nextIndex < 0 ? this.translate.instant('RD_DRVIER_LABEL') : this.translate.instant('RD_OTHER_LABEL');
    } else if (tabValue === 'Next') {
      const nextIndex = this.categories.map((obj) => this.translate.instant(obj.name)).indexOf(this.translate.instant('RD_OTHER_LABEL'));
      this.selectedTab = nextIndex < 0 ? this.translate.instant('RD_VEHICLE_LABEL') : this.translate.instant('RD_OTHER_LABEL');
      this.drsegment = nextIndex < 0 ? this.translate.instant('RD_VEHICLE_LABEL') : this.translate.instant('RD_OTHER_LABEL');
    } else {
      this.selectedTab = this.translate.instant(tabValue);
      this.drsegment = this.translate.instant(tabValue);
    }
    if(elem){
    setTimeout(() => {
      this.scroll(elem);  
    }, 0);
  }
  }
  selectDate() {
    this.updateDate();
  }
  selectTime() {
    this.updateDate();
  }
  updateDate() {
    if (this.selDate && this.selTime) {
      const index = this.selDate.indexOf('T');
      const date = this.selDate.substr(0, index);
      const time = this.selTime.substr(index);
      const properDate = date.concat(time);
      this.damageModel.damage.damageDateTime = new Date(new Date(properDate).toUTCString()).toISOString();
    } else {
      return;
    }
  }
  setFieldsForEuroMaster(json) {
    this.reportTypeDamage = json.euroMasterDamage;
    this.categories = json.euroMasterCategories;
    this.euromasterFormGroup = this.createEuromasterForm();
    this.ownerFormGroup = this.createOwnerForm();
    this.isMaster = true;
    this.damageModel = json.euroMasterObj;
    this.fileTypes = json.allowedFileTypeForEuroMaster;
    this.selectedLabel = 'RD_MASTER_LABEL';
    this.drsegment = this.translate.instant('RD_MASTER_LABEL');
    this.selectedTab = this.translate.instant('RD_MASTER_LABEL');
  }
  getEuromasterFunction() {
    const phone = ',' + this.employeePhone;
    const email = ',' + this.employeeEmail;
    const concatedStr = phone.concat(email);
    const finalStr = this.damageModel.euromaster.employeeFunction.concat(concatedStr);
    return finalStr;
  }
  reportDamageForm() {
    this.isSubmitted = true;
    this.checkValidity();
    // tslint:disable-next-line: max-line-length
    // console.log('valid forms are.euromasterFormGroup', (this.euromasterFormGroup && this.euromasterFormGroup.valid), 'damageFormGroup::', this.damageFormGroup.valid, ' driverFormGroup :: ', (this.driverFormGroup && this.driverFormGroup.valid), ' vehicleFormGroup::', this.vehicleFormGroup.valid, ' ownerFormGroup:: ', (this.ownerFormGroup && this.ownerFormGroup.valid));
    if (this.damageModel.kind === null || !this.damageFormGroup.valid
      || !this.vehicleFormGroup.valid || (this.otherPartyFormGroup && !this.otherPartyFormGroup.valid)) {
      //
      this.coreService.presentToastWithOptions(this.translate.instant('RD_OTHER_LABEL2'), 'warning');
      return;
    } else {
      this.coreService.showLoader();
      if (this.isLoggedInUser) {
        if (this.damageModel.targets.length === 0 || !this.driverFormGroup.valid) {
          this.coreService.hideLoader();
          this.coreService.presentToastWithOptions(this.translate.instant('RD_OTHER_LABEL2'), 'warning');
          return;
        }     
        this.damageModel.driver.driverName = this.sanitizeInput(this.damageModel.driver.driverName);
        this.isDisabled = true;
        if(this.isDisabled){
        this.damageService.reportNewDamage(this.damageModel).subscribe((resp: any) => {
          this.coreService.presentToastWithOptions(this.translate.instant('MSG11'), 'success');
          this.isDisabled = false;
          this.coreService.hideLoader();
          this.reloadPageData();  
        }, (error) => {
          this.handleError(error);
          this.isDisabled = false;
          this.cdRef.detectChanges();
        });
      }
      } else {
        if (this.isMaster) {
          this.damageModel.targets = [];
          this.damageModel.targets.push('Vehicle');
          this.damageModel.euromaster.employeeFunction = this.getEuromasterFunction();
          // tslint:disable-next-line: max-line-length
          this.damageModel.damage.eventLocation = !this.damageModel.damage.eventLocation ? 'Keine Angabe' : this.damageModel.damage.eventLocation;
         if (this.euromasterFormGroup.valid && this.ownerFormGroup.valid) {
            this.damageService.euromasterReportNewDamage(this.damageModel).subscribe((resp: any) => {
              this.coreService.presentToastWithOptions(this.translate.instant('MSG31'), 'success');
              this.coreService.hideLoader();
              this.reloadPageData();
            }, (error) => {
              this.handleError(error);
            });
          } else {
            this.coreService.presentToastWithOptions(this.translate.instant('RD_OTHER_LABEL2'), 'warning');
            return;
          }
        } else {
          this.coreService.hideLoader();
          if (this.damageModel.targets.length === 0 || !this.driverFormGroup.valid) {
            this.coreService.presentToastWithOptions(this.translate.instant('RD_OTHER_LABEL2'), 'warning');
            return;
          }
          this.damageService.reportNewDamageForUnauth(this.damageModel).subscribe((resp: any) => {
            this.coreService.presentToastWithOptions(this.translate.instant('MSG11'), 'success');
            this.coreService.hideLoader();
            this.reloadPageData();
          }, (error) => {
            this.handleError(error);
          });
        }
      }
    }
  }
  handleError(error) {
    if (error.status === 400) {
      this.coreService.presentAlert(this.translate.instant('RD_OTHER_LABEL2'));
    }
  }
  reloadPageData() {
    var url = this.router.url;
      this.events.subscribe('alertEvent', (data) => {
        this.coreService.showLoader();
        this.events.unsubscribe('alertEvent');
        if(this.isApp) {
          this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
            this.router.navigated = false;
            this.router.navigate([url]);
          });

        } else {
          window.location.reload();
        }

      });
  }
  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const testStr = reader.result.toString();
      const returnStr = testStr.substr(testStr.indexOf(',') + 1);
      resolve(returnStr);
    };
    reader.onloadend = () => {
      const testStr = reader.result.toString();
      const returnStr = testStr.substr(testStr.indexOf(',') + 1);
      resolve(returnStr);
    };
    reader.onerror = error => {
      console.log('Error', reader.result);
      reject(error);
    };
  })
  async presentActionSheet(ev) {
    const isApp = this.platform.is('pwa');
    console.log('isapp', isApp);
    if (this.isApp && !this.isBrowser) {
      const actionSheet = await this.actionSheetCtrl.create({
        header: this.translate.instant('SELECT_LABEL'),
        buttons: [
          {
            text: this.translate.instant('CAM_LABEL'),
            cssClass: 'camera_button',
            icon: 'camera',
            handler: () => {
              this.showCamera(this.camera.PictureSourceType.CAMERA);
              console.log('Destructive clicked');
            }
          },
          {
            text: this.translate.instant('GAL_LABEL'),
            cssClass: 'gallery_button',
            icon: 'images',
            handler: () => {
              this.showCamera(this.camera.PictureSourceType.SAVEDPHOTOALBUM);
              console.log('Archive clicked');
            }
          },
          {
            text: this.translate.instant('FUEL_LABEL8'),
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            }
          }
        ]
      });
      await actionSheet.present();
    } else {
      this.fileUpload(ev);
    }
  }
  showCamera(sType) {
    console.log('showCamera called with ', sType);
    const CameraOptions = {
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL, // this.camera.DestinationType.FILE_URI,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      sourceType: sType
    };
    this.camera.getPicture(CameraOptions).then((imageData) => {
      const count = new Date().getTime();
      const linkImage = imageData;
      console.log('imageData', imageData);
      if (this.imageBase64) {
        this.imageBase64.push({
          base64Data: linkImage,
          filename: 'DIimage' + count + '.jpeg'
        });
      } else {
        this.imageBase64 = [];
        this.imageBase64.push({
          base64Data: linkImage,
          filename: 'DIimage' + count + '.jpeg'
        });
      }
      this.damageModel.attachments = this.imageBase64;
    }, (err) => {
      // Handle error
    });
  }
  fileUpload(ev) {
    console.log('fileUpload---called', ev);
    const file = ev.type === 'change' ? ev.target.files[0] : ev.target.control.files[0];
    this.insertFiles(file);
  }
  fileUploadMaster(ev) {
    console.log('fileUploadMaster---called', ev);
    const file = ev.type === 'change' ? ev.target.files[0] : ev.target.control.files[0];
    this.insertFiles(file);
  }
  async insertFiles(file) {
    if (file) {
      if (this.validFileType(file)) {
        this.isInvalidFileType = false;
        if (this.isValidSize(file.size)) {
          this.isInvalidFileSize = false;
          const data = await this.toBase64(file);
          if (this.imageBase64) {
            this.imageBase64.push({
              base64Data: data,
              filename: file.name
            });
          } else {
            this.imageBase64 = [];
            this.imageBase64.push({
              base64Data: data,
              filename: file.name
            });
          }
          this.damageModel.attachments = this.imageBase64;
          console.log('this.imageBase64[0].base64Data:: ', data);
        } else {
          this.isInvalidFileSize = true;
          this.invalidSizeName = file.name;
        }
      } else {
        this.isInvalidFileType = true;
        this.invalidTypeName = file.name;
      }
    }
    console.log('this.damageModel.attachments', this.damageModel.attachments);
  }
  removeFile(filename) {
    this.imageBase64.forEach((fc, index) => {
      if (fc.filename === filename) {
        // this.damageModel.attachments.splice(index, 1);
        this.imageBase64.splice(index, 1);
      }
    });
    console.log('this.damageModel.attachments', this.imageBase64);
    this.damageModel.attachments = this.imageBase64;
  }
  onEnterKey(e) {
    console.log('event called with key:', e.KeyCode);
    if (e.keyCode === 13) {
      console.log('yay event called');
      const activeElement = document.activeElement as HTMLElement;
      activeElement && activeElement.blur ? activeElement.blur() : null;
    }
  }
  validFileType(file) {
    return this.fileTypes.includes(file.type);
  }
  returnFileSize(nbr) {
    if (nbr < 1024) {
      return nbr + 'bytes';
    } else if (nbr >= 1024 && nbr < 1048576) {
      return (nbr / 1024).toFixed(1) + 'KB';
    } else if (nbr >= 1048576) {
      return (nbr / 1048576).toFixed(1) + 'MB';
    }
  }
  isValidSize(nbr) {
    if (nbr > 52428800) {
      return false;
    } else {
      return true;
    }
  }
  toggleValues(fieldName, val) {
    console.log('toggleValues', fieldName, val, this.damageModel.euromaster.commercialCustomer, this.damageModel.euromaster.privateCustomer);
    if (fieldName === 'CC') {
      console.log('inside cc');
      if (this.damageModel.euromaster.commercialCustomer === null || this.damageModel.euromaster.commercialCustomer) {
        console.log('inside cc true');
        this.damageModel.euromaster.privateCustomer = false;
        this.damageModel.euromaster.commercialCustomer = true;
      } else {
        console.log('inside cc false');
        this.damageModel.euromaster.privateCustomer = true;
        this.damageModel.euromaster.commercialCustomer = false;
      }
    } else {
      console.log('inside pc');
      if (this.damageModel.euromaster.privateCustomer === null || this.damageModel.euromaster.privateCustomer) {
        this.damageModel.euromaster.commercialCustomer = false;
        this.damageModel.euromaster.privateCustomer = true;
      } else {
        this.damageModel.euromaster.commercialCustomer = true;
        this.damageModel.euromaster.privateCustomer = false;
      }
    }
    this.damageModel.euromaster.privateCustomer = this.damageModel.euromaster.commercialCustomer ? false : true;
  }

  sanitizeInput(input: string): string {
    const element = this.elementRef.nativeElement;
    element.innerHTML = input;
    const sanitizedInput = element.textContent;
    element.innerHTML = '';
    return sanitizedInput;
  }
}
