import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationProvider } from '../providers/authentication/authentication';


@Injectable({ providedIn: 'root' })
export class UnauthGuard implements CanActivate {
    /**
     *
     * @param route
     * @param state
     * description: this method is to retrict unauthorised user to access application pages
     */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser: any = this.authenticationService.currentUserData();
        if (!currentUser) {
            return true;
        } else {
            if (currentUser && currentUser.state === 'Valid' && currentUser.accessToken.token) {
                this.router.navigateByUrl('/dashboard').then(e => {
                    if (e) {
                        console.log('Navigation is successful!');
                    } else {
                        console.log('Navigation has failed!', e);
                    }
                });
                return false;
            } else if (currentUser && (currentUser.state === 'RegistrationRequired')) {
                this.router.navigateByUrl('/registration');
            } else {
                console.log('currentUser.state', currentUser.state);
            }
        }

    }
    constructor(private router: Router,
                private authenticationService: AuthenticationProvider) {

    }
}

