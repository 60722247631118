import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AlertController,
  ModalController,
  NavController,
  IonSlides,
  Platform,
} from "@ionic/angular";
import { ActivatedRoute } from "@angular/router";
import { FuelcardsProvider } from "src/app/providers/fuel-cards/fuel-cards";
import { SavedDataService } from "src/app/providers/saved-data/saved-data";
import { CoreProvider } from "src/app/providers/core/core.service";
import { TranslateService } from "@ngx-translate/core";
import { DriverDetailsProvider } from "src/app/providers/driver-details/driver-details";
import { DocumentProvider } from "src/app/providers/document/document";
import * as moment from "moment";
import { AssetsProvider } from "src/app/providers/assets/assets";
import { UpdateKmPage } from "../update-km/update-km.page";

declare var cordova: any;
@Component({
  selector: "app-my-vehicles",
  templateUrl: "./my-vehicles.page.html",
  styleUrls: ["./my-vehicles.page.scss"],
})
export class MyVehiclesPage implements OnInit {
  viewmore = false;
  isFirstSLide = false;
  isLastSLide = false;
  assetsList: any;
  cardsList: any;
  fcList: any;
  slideIndex: number;
  currentAsset: any;
  currentAssetId: string;
  currentCards: any;
  noData: string;
  documentList: any;
  carPolicy: any;
  serviceCardDoc: any;
  currentDocumentList: any;
  isMobileApp = false;

  @ViewChild(IonSlides, { static: false }) slides: IonSlides;
  slideOpts = {
    allowTouchMove: true,
    speed: 400,
    // autoplay: true,
  };
  actionableDoc: any;
  isSantander: string;
  slidePrev() {
    this.slides.slidePrev();
  }
  slideNext() {
    this.slides.slideNext();
  }

  constructor(
    private route: ActivatedRoute,
    public alertController: AlertController,
    public modalController: ModalController,
    public navCtrl: NavController,
    private savedData: SavedDataService,
    private driverService: DriverDetailsProvider,
    private documentService: DocumentProvider,
    private platform: Platform,
    private coreService: CoreProvider,
    private translate: TranslateService
  ) {
    this.route.params.subscribe((val) => {
      this.isSantander = localStorage.getItem("isSantander");
      this.getAssetList();
      this.translate.get("MSG18").subscribe((res) => {
        this.noData = res;
      });
    });
  }

  ngOnInit() {
    this.route.data.subscribe((data: any) => {
      console.log(
        "this.savedData.assetId",
        this.savedData.assetId,
        this.currentAssetId
      );
      this.isMobileApp = this.platform.is("cordova");
      console.log("data", data);
      const routeData = this.route.snapshot.data.list;
      if (routeData != null) {
        this.documentList = [];
        this.serviceCardDoc = null;
        this.documentList = routeData.items;
        if (this.documentList && this.documentList.length) {
          this.getDocumentsById();
        }
      }
    });
  }
  ionViewDidEnter() {
    if (this.slides) {
      this.slides.slideTo(0);
    }
    this.coreService.hideLoader();
  }
  ionViewWillEnter() {
    this.isMobileApp = this.platform.is("cordova");
    this.getCarPolicy();
    if (this.slides) {
      this.slides.getActiveIndex().then((index) => {
        this.slideIndex = index;
        if (index === 0) {
          this.isFirstSLide = true;
        }
      });
    }
    this.translate.use(this.translate.getDefaultLang());
  }
  /**
   * @description : Method to go to next slide
   */
  goNext() {
    this.slideIndex++;
    this.isLastSLide =
      this.slideIndex >= this.assetsList.length - 1 ? true : false;
    this.isFirstSLide = this.slideIndex === 0 ? true : false;
    this.currentAssetId = this.assetsList[this.slideIndex].id;
    // this.getCardList();
    this.slides.slideNext();
  }
  /**
   * @description : Method to go to previous slide
   */
  goPrev() {
    this.slideIndex--;
    this.isFirstSLide = this.slideIndex === 0 ? true : false;
    this.isLastSLide =
      this.slideIndex >= this.assetsList.length - 1 ? true : false;
    this.currentAssetId = this.assetsList[this.slideIndex].id;
    // this.getCardList();
    this.slides.slidePrev();
  }
  getAssetList() {
    if (this.savedData && this.savedData.assetsList) {
      this.savedData.assetsList.subscribe((assetData) => {
        if (assetData && assetData.items) {
          this.assetsList = assetData.items;
          this.currentAssetId = this.assetsList[0].id;
          this.currentAsset = this.assetsList[0];
          // this.getFuelCards();
          // this.assetsList.forEach((asset, i) => {
          //   if (asset.assetId === this.currentAssetId) {
          //     this.currentAsset.push(this.assetsList[i]);
          //   }
          // });
        }
      });
    } else {
      this.assetsList = null;
      this.currentAsset = null;
      this.currentCards = [];
    }
  }
  // getFuelCards() {
  //   this.fuelCardService.getFuelCards().subscribe((cardData: any) => {
  //     this.cardsList = cardData.items;
  //     this.getCardList();
  //     console.log('this.cardsList', this.cardsList);
  //   }, (error) => {
  //     this.cardsList = null;
  //     this.currentCards = [];
  //   });
  // }
  // getCardList() {
  //   this.currentCards = [];
  //   this.cardsList.forEach(fc => {
  //     if (fc.assetId === this.currentAssetId) {
  //       this.currentCards.push(fc);
  //     }
  //   });
  // }
  slideChanged() {
    this.slides.getActiveIndex().then((index) => {
      this.isLastSLide = index >= this.assetsList.length - 1 ? true : false;
      this.isFirstSLide = index === 0 ? true : false;
      this.currentAssetId = this.assetsList[index].id;
      this.currentAsset = this.assetsList[index];
      console.log("slideChanged", index, this.currentAssetId);
      console.log(this.currentAssetId);
      // this.getCardList();
      if (this.documentList && this.documentList.length) {
        this.getDocumentsById();
      }
    });
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: UpdateKmPage,
      componentProps: {
        lastOdometerReading:
          this.currentAsset.lastOdometer.distance +
          " " +
          this.currentAsset.lastOdometer.measure,
        readingDate: this.currentAsset.lastOdometer.readingDate,
        currentAssetId: this.currentAssetId,
      },
    });
    return await modal.present();
  }

  /*
   *
   * @description : method to get Car policy document
   */
  getCarPolicy() {
    console.log("car-policy called");
    this.coreService.showLoader();
    this.driverService
      .getDriverDetailByFilter("CarPolicy($expand=Data)")
      .subscribe(
        (resp: any) => {
          console.log("getCarPolicy policy", resp);
          if (resp && resp.carPolicy) {
            // this.documentList.splice(0, 0, resp.carPolicy);
            this.carPolicy = resp.carPolicy;
            console.log("resp policy", resp);
          }
          this.coreService.hideLoader();
        },
        (err) => {
          console.log("err car-policy called", err);
          this.coreService.hideLoader();
        }
      );
  }
  /**
   *
   * @param id
   * @description: method to download document based on Id
   */
  downloadDoc(id, obj) {
    this.coreService.showLoader();
    if (!obj) {
      this.documentService.getDocumentById(id).subscribe(
        (resp: any) => {
          if (resp && resp.data && resp.mimeType) {
            this.actionableDoc = resp;
            this.download();
          } else {
            this.coreService.hideLoader();
          }
        },
        (err) => {
          console.log("Error while getting document to download", err);
          this.coreService.hideLoader();
        }
      );
    } else {
      this.actionableDoc = obj;
      this.actionableDoc.name = "car-policy";
      this.download();
    }
  }
  download() {
    if (this.platform.is("cordova")) {
      let folderPath = this.platform.is("android")
        ? cordova.file.dataDirectory
        : cordova.file.documentsDirectory;
      // tslint:disable-next-line: max-line-length
      folderPath = cordova.file.dataDirectory;
      // tslint:disable-next-line: max-line-length
      this.documentService.downloadOnDevciebase64AsPDF(
        this.actionableDoc.name,
        this.actionableDoc.data.base64Data,
        this.actionableDoc.mimeType,
        folderPath
      );
    } else {
      // console.log('downloaddownload', download);
      this.documentService.downloadDocument(
        this.actionableDoc.mimeType,
        this.actionableDoc.data.base64Data,
        this.actionableDoc.name
      );
      // download(resp.data.base64Data, resp.name, resp.mimeType);
    }
    this.actionableDoc = null;
    this.coreService.hideLoader();
  }
  /**
   *
   * @param id
   * @description: method to preview document based on Id
   */
  previewDocument(id, obj) {
    this.coreService.showLoader();
    if (!obj) {
      this.documentService.getDocumentById(id).subscribe(
        (resp: any) => {
          console.log("API resp", resp);
          if (resp && resp.data && resp.mimeType) {
            this.actionableDoc = resp;
            this.preview();
          }
        },
        (err) => {
          console.log("Error while getting document to preview", err);
          this.coreService.hideLoader();
        }
      );
    } else {
      this.actionableDoc = obj;
      this.actionableDoc.name = "car-policy";
      this.preview();
    }
  }
  preview() {
    if (this.actionableDoc) {
      this.documentService.previewDocument(
        this.actionableDoc.mimeType,
        this.actionableDoc.data.base64Data,
        this.actionableDoc.name
      );
      this.actionableDoc = null;
      this.coreService.hideLoader();
    }
  }
  getDocumentsById() {
    this.currentDocumentList = [];
    this.serviceCardDoc = null;
    this.documentList.forEach((doc) => {
      if (doc.assetId === this.currentAssetId) {
        console.log(
          doc.description.indexOf("Accident_Fahrer_Bilder"),
          "index for image"
        );
        if (
          doc.description.indexOf("Accident_Fahrer_Bilder") < 0 &&
          doc.description.indexOf("Grüne Versicherungskarte") < 0
        ) {
          this.currentDocumentList.push(doc);
        }
        if (doc.description === "Grüne Versicherungskarte") {
          this.serviceCardDoc = doc;
        }
      }
    });
  }
}
