import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportDamageComponent } from './report-damage/report-damage.component';
import { AssetHeaderComponent } from './asset-header/asset-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../pipes/pipes.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { ServiceCardComponent } from './service-card/service-card.component';
import { SantanderServiceCardComponent } from './santander-service-card/santander-service-card.component';
import { OwlDateTimeIntl, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
export class DefaulPickertIntl extends OwlDateTimeIntl  {
    /** A label for the cancel button */
    cancelBtnLabel = 'Abbrechen';
    /** A label for the set button */
    setBtnLabel = 'Bestätigen';
  }
@NgModule({
    imports: [
        PipesModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
      ],
      providers: [
        {provide: OwlDateTimeIntl, useClass: DefaulPickertIntl},
    ],
    exports: [AssetHeaderComponent, ReportDamageComponent, ServiceCardComponent, SantanderServiceCardComponent],
    declarations: [AssetHeaderComponent, ReportDamageComponent, ServiceCardComponent, SantanderServiceCardComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})

export class ComponentsModule {}
