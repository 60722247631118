import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth.guard";
import { UnauthGuard } from "./guards/unauth.guard";
import { AssetResolver } from "./providers/assets/asset.resolver";
import { AppointmentResolver } from "./providers/appointments/appointment.resolver";
import { ContractResolver } from "./providers/contracts/contract.resolver";
import { DamageResolver } from "./providers/damages/damage.resolver";
import { FuelCardResolver } from "./providers/fuel-cards/fuel-card.resolver";
import { DriverDetailResolver } from "./providers/driver-details/driver-detail.resolver";
import { AssetConfigResolver } from "./providers/assets/asset-config.resolver";
import { DamageDetailResolver } from "./providers/damages/damage-detail.resolver";
import { DocumentResolver } from "./providers/document/document.resolver";
import { FaqResolver } from "./providers/faq/faq.resolver";
import { WorkshopResolver } from "./providers/workshops/workshop.resolver";
import { AccessGuard } from "./guards/access.guard";
import { PolicyGuardService } from "./guards/policy-guard";
import { NotificationResolver } from "./providers/notification/notification.resolver";

const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: "./pages/login/login.module#LoginPageModule",
    canActivate: [UnauthGuard],
  },
  {
    path: "dashboard",
    loadChildren: "./pages/dashboard/dashboard.module#DashboardPageModule",
    data: {
      title: "Dashboard",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "appointments",
    loadChildren:
      "./pages/appointments/appointments.module#AppointmentsPageModule",
    data: {
      title: "MENU1",
    },
    canActivate: [AccessGuard],
    resolve: {
      list: AppointmentResolver,
    },
  },
  {
    path: "fuel-cards",
    loadChildren: "./pages/fuel-cards/fuel-cards.module#FuelcardsPageModule",
    data: {
      title: "FUEL_LABEL",
    },
    canActivate: [AccessGuard],
    resolve: {
      list: FuelCardResolver,
    },
  },
  {
    path: "driver-detail",
    loadChildren:
      "./pages/driver-details/driver-details.module#DriverDetailsPageModule",
    canActivate: [AccessGuard],
    data: {
      title: "USER_DATA",
    },
    resolve: {
      detail: DriverDetailResolver,
    },
  },
  {
    path: "damages",
    loadChildren: "./pages/damages/damages.module#DamagesPageModule",
    data: {
      title: "DAMG_LABEL1",
    },
    canActivate: [AccessGuard],
    resolve: {
      list: DamageResolver,
    },
  },
  {
    path: "my-vehicles",
    loadChildren: "./pages/my-vehicles/my-vehicles.module#MyVehiclesPageModule",
    data: {
      title: "MY_VEHICLE_LABEL",
    },
    canActivate: [AccessGuard],
    resolve: {
      list: DocumentResolver,
    },
  },
  {
    path: "contracts",
    loadChildren: "./pages/contracts/contracts.module#ContractsPageModule",
    data: {
      title: "D_LABEL1",
    },
    canActivate: [AccessGuard],
    resolve: {
      list: ContractResolver,
    },
  },
  {
    path: "registration/:token",
    loadChildren:
      "./pages/registration/registration.module#RegistrationPageModule",
  },

  {
    path: "damage-detail/:id",
    loadChildren:
      "./pages/damage-details/damage-details.module#DamageDetailsPageModule",
    data: {
      title: "DAMG_DE_LABEL1",
    },
    canActivate: [AuthGuard],
    resolve: {
      detail: DamageDetailResolver,
    },
  },
  {
    path: "edit-driver-detail",
    loadChildren:
      "./pages/edit-driver-details/edit-driver-details.module#EditDriverDetailsPageModule",
    data: {
      title: "USER_DATA",
    },
    canActivate: [AuthGuard],
    // resolve: {
    //   detail: DriverDetailResolver
    // }
  },
  {
    path: "faq",
    loadChildren: "./pages/faq/faq.module#FaqPageModule",
    data: {
      title: "FAQ_LABEL",
    },
    canActivate: [AuthGuard],
    resolve: {
      list: FaqResolver,
    },
  },
  {
    path: "car-configurator",
    loadChildren:
      "./pages/car-configurator/car-configurator.module#CarConfiguratorPageModule",
    data: {
      title: "CC_LABEL",
    },
    canActivate: [AccessGuard],
    resolve: {
      detail: AssetConfigResolver,
    },
  },
  {
    path: "get-directions-page",
    loadChildren:
      "./pages/get-directions-page/get-directions-page.module#GetDirectionsPagePageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "report-damage",
    loadChildren:
      "./pages/report-damage/report-damage.module#ReportDamagePageModule",
    data: {
      title: "QuickLink1",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "license-check",
    loadChildren:
      "./pages/license-check/license-check.module#LicenseCheckPageModule",
    data: {
      title: "LICENSE_LABEL",
    },
    canActivate: [AccessGuard],
    resolve: {
      detail: DriverDetailResolver,
    },
  },
  {
    path: "workshops",
    loadChildren: "./pages/workshops/workshops.module#WorkshopsPageModule",
    data: {
      title: "VENDOR_LABEL",
    },
    canActivate: [AccessGuard],
    resolve: {
      list: WorkshopResolver,
    },
  },
  {
    path: "email",
    loadChildren: "./pages/email/email.module#EmailPageModule",
    canActivate: [AuthGuard],
  },
  {
    path: "forgot-password-challenge/:token",
    loadChildren:
      "./pages/forgot-password-challenge/forgot-password-challenge.module#ForgotPasswordChallengePageModule",
  },
  {
    path: "contact-ari",
    loadChildren: "./pages/contact-ari/contact-ari.module#ContactAriPageModule",
    data: {
      title: "QuickLink2",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "contact",
    loadChildren: "./pages/contact/contact.module#ContactPageModule",
    data: {
      title: "FAQ_LABEL3",
    },
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'documents',
  //   loadChildren: './pages/documents/documents.module#DocumentsPageModule',
  //   canActivate: [AuthGuard],
  //   resolve: {
  //     list: DocumentResolver
  //   }

  // },
  {
    path: "request-registration",
    loadChildren:
      "./pages/request-registration/request-registration.module#RequestRegistrationPageModule",
    canActivate: [UnauthGuard],
  },
  {
    path: "privacy-policy",
    loadChildren:
      "./pages/privacy-policy/privacy-policy.module#PrivacyPolicyPageModule",
    // canActivate: [PolicyGuardService]
  },
  {
    path: "request-simulation/:token",
    loadChildren:
      "./pages/request-simulation/request-simulation.module#RequestSimulationPageModule",
  },
  {
    path: "settings",
    loadChildren: "./pages/settings/settings.module#SettingsPageModule",
    data: {
      title: "SETTING_LABEL",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "mydata",
    loadChildren: "./pages/my-data/my-data.module#MyDataModule",
    data: {
      title: "APP_LABEL9",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "thank-you",
    loadChildren: "./pages/thank-you/thank-you.module#ThankYouPageModule",
    // canActivate: [UnauthGuard],
  },
  {
    path: "notifications",
    loadChildren:
      "./pages/notifications/notifications.module#NotificationsPageModule",
    data: {
      title: "APP_LABEL11",
    },
    canActivate: [AuthGuard],
    resolve: {
      list: NotificationResolver,
    },
  },
  {
    path: "service-card",
    loadChildren:
      "./pages/service-card/service-card.module#ServiceCardPageModule",
    data: {
      title: "SERVICE_LABEL",
    },
    canActivate: [AuthGuard],
  },
  {
    path: "service-appointment/:requestKey",
    loadChildren:
      "./pages/vendor-appointment/vendor-appointment.module#VendorAppointmentPageModule",
  },
  {
    path: "schedule-service-appointment",
    loadChildren:
      "./pages/schedule-appointment/schedule-appointment.module#ScheduleAppointmentPageModule",
    data: {
      title: "SCH_SER_APPT_LABEL",
    },
  },
  // {
  //   path: 'update-km',
  //   loadChildren: './pages/update-km/update-km.module#UpdateKmPageModule'
  // },
  {
    path: "fullscreen-modal",
    loadChildren:
      "./pages/fullscreen-modal/fullscreen-modal.module#FullscreenModalPageModule",
  },
  {
    path: "damage-survey-external",
    loadChildren:
      "./pages/damage-survey-external/damage-survey-external.module#DamageSurveyExternalPageModule",
    canActivate: [UnauthGuard],
  },
  {
    path: "damage-survey-external/euromaster",
    loadChildren:
      "./pages/damage-euromaster/damage-euromaster.module#DamageEuromasterPageModule",
    canActivate: [UnauthGuard],
  },
  {
    path: "not-found",
    loadChildren: "./pages/not-found/not-found.module#NotFoundPageModule",
  },
  {
    path: "404",
    loadChildren: "./pages/not-found/not-found.module#NotFoundPageModule",
  },
  { path: "**", redirectTo: "404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
  providers: [
    AppointmentResolver,
    FuelCardResolver,
    DamageResolver,
    AssetResolver,
    ContractResolver,
    DriverDetailResolver,
    AssetConfigResolver,
    DocumentResolver,
    FaqResolver,
    WorkshopResolver,
    NotificationResolver,
  ],
})
export class AppRoutingModule {}
