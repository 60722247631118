import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-santander-service-card',
  templateUrl: './santander-service-card.component.html',
  styleUrls: ['./santander-service-card.component.scss'],
})
export class SantanderServiceCardComponent implements OnInit {
  @Input() serviceCardData: any;

  constructor() { }

  ngOnInit() {}

}
