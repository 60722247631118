import { Injectable } from "@angular/core";
import {
  Router,
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";

import { AuthenticationProvider } from "../authentication/authentication";
import { take, mergeMap } from "rxjs/operators";
import { of, EMPTY } from "rxjs";
import { CoreProvider } from "../core/core.service";
import { DamagesProvider } from "./damage";
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class DamageResolver implements Resolve<any> {
  cardsList: any;
  assetsList: any;
  data: any;
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authenticationService.currentUserData()) {
      return this.damageService.getDamageList().pipe(
        take(1),
        mergeMap((data) => {
          const list: any = data;
          if (list) {
            return of(data);
          } else {
            this.coreService.presentToastWithOptions(
              this.translate.instant("MSG24"),
              "primary"
            );
            this.coreService.addClassToBlankMenu("Schäden");
            this.coreService.hideLoader();
            return EMPTY;
          }
        })
      );
    }
  }

  constructor(
    private authenticationService: AuthenticationProvider,
    private damageService: DamagesProvider,
    private coreService: CoreProvider,
    private translate: TranslateService,
    private router: Router
  ) {}
}
