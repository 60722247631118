import { NgModule } from '@angular/core';
import { FormatNumberPipe } from './format-number.pipe';


@NgModule({
declarations: [FormatNumberPipe],
imports: [],
exports: [FormatNumberPipe],
})

export class PipesModule {}