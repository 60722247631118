import { HttpClient, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthenticationProvider } from '../authentication/authentication';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FuelcardsProvider {

  constructor(private http: HttpClient, private authenticationService: AuthenticationProvider) {}
  /**
   * api for fuel cards
   */
  getFuelCards() {
    return this.http.get(`${environment.apiUrl}me/fuel-cards`).pipe(
      map((cards: Response) => {
        return cards;
      }));
  }
  /**
   * @description: API to get cancel-reasons for fuel cards
   */
  getCancelReasons() {
    return this.http.get(`${environment.apiUrl}me/fuel-cards/cancel-reasons`).pipe(
      map((reasons: Response) => {
        return reasons;
      }));
  }
  /**
   *
   * @param cardNumber
   * @param formdata
   * @description API call to cancel fuel card and reorder fuel card
   */
  cancelFuelCard(cardNumber, formdata) {
    return this.http.put(`${environment.apiUrl}me/fuel-cards/${cardNumber}/deactivate`, formdata, { observe: 'response' });
  }
}
