import { Component, OnInit, ViewChild, OnDestroy, Input } from "@angular/core";
import { SavedDataService } from "src/app/providers/saved-data/saved-data";
import { ActivatedRoute } from "@angular/router";
import { CoreProvider } from "src/app/providers/core/core.service";
import { IonSlides, Events } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AssetsProvider } from "src/app/providers/assets/assets";

@Component({
  selector: "app-asset-header",
  templateUrl: "./asset-header.component.html",
  styleUrls: ["./asset-header.component.scss"],
})
export class AssetHeaderComponent implements OnInit {
  assetsList: any;
  currentAssetId: any;
  @Input() title: any;
  @ViewChild(IonSlides, { static: false }) slides: IonSlides;
  slideOpts = {
    speed: 400,
  };
  isLastSLide: boolean;
  isFirstSLide: boolean;
  currentAsset: any;
  currentAppointments: any[];
  currentServiceAppointments: any[];
  appointmentsList: any;
  serviceAppointmentList: any;
  isEventPublished = false;

  constructor(
    public savedData: SavedDataService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private assetService: AssetsProvider,
    private events: Events,
    private coreService: CoreProvider
  ) {
    route.params.subscribe((val) => {
      console.log("asset header calld");
      this.isEventPublished = false;
    });
  }

  ngOnInit() {
    console.log("Slides are here", this.slides);
    this.getAssetData();
    // setTimeout
    //
  }
  ionViewWillLeave() {
    console.log("Called on asset header");
  }
  onSlideLoad() {
    console.log("onSlideLoad", this.slides, this.currentAssetId);
    this.isEventPublished = true;
    this.slides.slideTo(0);
    this.slides.getActiveIndex().then((index) => {
      const assetData =
        this.assetsList && this.assetsList.length ? this.assetsList[0] : null;
      console.log("Publishing method");
      if (index === 0) {
        this.isFirstSLide = true;
        console.log("IsFirst slide");
      }
      this.events.publish("slideChanged", { asset: assetData });
    });
  }

  /**
   * @description : method to get AssetData
   */
  getAssetData() {
    if (this.savedData && this.savedData.assetsList) {
      this.savedData.assetsList.subscribe((assetData) => {
        if (assetData && assetData.items) {
          this.assetsList = assetData.items;
          this.currentAssetId = this.assetsList[0].id;
          setTimeout(() => {
            if (this.slides && !this.isEventPublished) {
              console.log("calling load method");
              this.onSlideLoad();
              // this.events.publish('slideChanged', {asset: this.assetsList });
            }
          }, 800);
        }
      });
    } else {
      if (!this.savedData.isAssetsAPICalling) {
        this.assetService.getAssetList("Driver").subscribe(
          (resp: any) => {
            if (resp && resp.items && resp.items.length) {
              localStorage.setItem("assets", JSON.stringify(resp));
              this.savedData.setAssetData(resp);
              this.getImageData();
            }
          },
          (err) => {
            console.log("Error while fetching asset data:: ", err);
          }
        );
      } else {
        setTimeout(() => {
          this.getAssetData();
        }, 200);
      }
    }
  }

  getImageData() {
    this.savedData.assetsList.subscribe((assetData) => {
      if (assetData && assetData.items) {
        assetData.items.forEach((asset, i) => {
          if (!asset.image) {
            this.assetService.getAssetById(asset.id).subscribe((resp: any) => {
              assetData.items[i].image = resp.image;
            });
          }
        });
        this.getAssetData();
      }
    });
  }
  /**
   * @description: method handles slide change event
   */
  slideChanged() {
    this.slides.getActiveIndex().then((index) => {
      this.isLastSLide = index >= this.assetsList.length - 1 ? true : false;
      this.isFirstSLide = index === 0 ? true : false;
      this.currentAssetId = this.assetsList[index].id;
      this.currentAsset = this.assetsList[index];
      this.events.publish("slideChanged", {
        assetId: this.currentAssetId,
        asset: this.currentAsset,
      });
    });
  }
  /**
   * @description: method to move slide previos
   */
  slidePrev() {
    this.slides.slidePrev();
  }
  /**
   * @description: method to move slide next
   */
  slideNext() {
    this.slides.slideNext();
  }
}
