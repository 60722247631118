import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber'
})
export class FormatNumberPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (!value) {
      return value;
  } else {
    let num = typeof value === 'string' ? parseFloat(value) : value;
    // console.log(value, '=========', typeof value, num);
    // tslint:disable-next-line: quotemark
    // let str = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    let option = { minimumIntegerDigits: 1, minimumFractionDigits: 0, maxFractionDigits: 2 };
    return new Intl.NumberFormat('de-DE', option).format(num);
    // return  value.toLocaleString('de-DE');
  }
  }

}
