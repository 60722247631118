import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';


import { AuthenticationProvider } from '../authentication/authentication';
import { take, mergeMap } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';
import { CoreProvider } from '../core/core.service';
import { DriverDetailsProvider } from './driver-details';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class DriverDetailResolver implements Resolve<any> {
    cardsList: any;
    assetsList: any;
    data: any;
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('route is :: ', route.url, route.routeConfig.path);
        // tslint:disable-next-line: triple-equals
        const filter = route.routeConfig.path != 'license-check' ? 'Editable,HomeToWork' : 'License';
        if (this.authenticationService.currentUserData()) {
            return this.driverService.getDriverDetailByFilter(filter).pipe(
                take(1),
                mergeMap(data => {
                    const list: any = data;
                    if (list) {
                        return of(data);
                    } else {
                        this.coreService.presentToastWithOptions(this.translate.instant('MSG18'), 'primary');
                        const label = filter === 'License' ? 'Führerscheinprüfung' : 'Meine Daten';
                        this.coreService.addClassToBlankMenu(label);
                        this.router.navigate(['/mydata']);
                        if (this.router.url === '/mydata') {
                            this.coreService.hideLoader();
                        }
                        return EMPTY;
                    }
                })
            );
        }
    }

    constructor(private authenticationService: AuthenticationProvider,
                private driverService: DriverDetailsProvider,
                private coreService: CoreProvider,
                private translate: TranslateService,
                private router: Router) { }
}
