import { Component, OnInit, Input } from '@angular/core';
import {  PopoverController, Events } from '@ionic/angular';

@Component({
  selector: 'app-questions-popover',
  templateUrl: './questions-popover.page.html',
  styleUrls: ['./questions-popover.page.scss'],
})
export class QuestionsPopoverPage implements OnInit {
  @Input() data;
  @Input() type;

  constructor( private events: Events, private popoverController: PopoverController) {
  }
   ionViewWillEnter() {}
  ngOnInit() {  }
  setQuestion(ques) {
    console.log('selected ques is ::', ques);
    this.events.publish('fromPopoverEvent', {data: ques});
    this.popoverController.dismiss();
  }
  setReason(reason) {
    console.log('selected reason is ::', reason);
    this.events.publish('fromReasonPopoverEvent', {data: reason});
    this.popoverController.dismiss();
  }
  setTopic(topic) {
    console.log('selected topic is ::', topic);
    this.events.publish('fromTopicPopoverEvent', {data: topic});
    this.popoverController.dismiss();
  }
  setAssetData(asset){
    console.log('selected asset is :: ', asset);
    this.events.publish('fromAssetPopoverEvent', {data: asset});
    this.popoverController.dismiss();
  }
  selectRadius(radius) {
    console.log('selected radius is :: ', radius);
    this.events.publish('fromRadiusEvent', {data: radius});
    this.popoverController.dismiss();
  }

}
